import React, { useState } from 'react';
import { Badge, Button, Card, Col, Dropdown, Form, Nav, Row, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import SimpleBar from 'simplebar-react';
import AvatarUploader from 'react-avatar-uploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBehance, faDropbox, faGithub, faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import { Activity, CheckSquare, Clock, Edit2, Edit3, Heart, Mail, Phone, Plus, Shield, Trash, Trash2, Upload, Video, Zap } from 'react-feather';
import HkTooltip from '../../../components/@hk-tooltip/HkTooltip';
//Internal Components
import MoreInfo from './MoreInfo';
import AddTags from './AddTags';
import AddBio from './AddBio';

//Image
import avatar2 from '../../../assets/dist/img/avatar2.jpg';
import AddNewTask from './Schedule/AddNewTask';
import TaskList from './Task/Tasklist';
import { useForm } from 'react-hook-form';
import axios from 'axios'
import RegexUtils from '../../../utils/RegexUtils';
import AlertMsg from '../../../utils/Alert';
import ReactShowMoreText from 'react-show-more-text';
import UpdateStage from './UpdateStage';
import Notes from './Notes';
import Schedule from './Schedule';
import { useSelector } from 'react-redux';
import AvatarLetters from '../../../utils/AvatarLetters';
import classNames from 'classnames';
import EditInfo from './EditInfo';

const EditLeadBody = ({ data, reload, setReload }) => {
    const [editInfo, setShowEditInfo] = useState(false);
    const [moreInfo, setMoreInfo] = useState(false);
    const [addTags, setAddTags] = useState(false);
    const [addBio, setAddBio] = useState(false);
    const [updateStage, showUpdateStage] = useState(false)
    // const [note, setNote] = useState('')

    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
    const stages = useSelector(state => state.stagesReducer.stages)
    console.log(stages)
    return (
        <>
            <div className="contact-body contact-detail-body">
                <div className='position-fixed z-index-3 w-100'>
                    <AlertMsg data={alertData} show={showAlert} setShow={setAlertShow} />
                </div>
                <SimpleBar className="nicescroll-bar">
                    <div className="d-flex flex-xxl-nowrap flex-wrap">
                        <div className="contact-info w-lg-30 w-100">
                            {/* <Dropdown className="action-btn">
                                <Dropdown.Toggle variant="light">Action</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>Action</Dropdown.Item>
                                    <Dropdown.Item>Another action</Dropdown.Item>
                                    <Dropdown.Item>Something else here</Dropdown.Item>
                                    <Dropdown.Divider as="div" />
                                    <Dropdown.Item>Separated link</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}
                            <div className="text-center mt-5">
                                {/* <div className="mx-auto" style={{ width: 118 }} >
                                    <AvatarUploader
                                        name="avatar"
                                        size={118}
                                        defaultImg={avatar2}
                                        uploadURL="http://localhost:3000"
                                        placeholder="string"
                                        fileType={"image/jpg"}
                                        onFinished={(err, res) => console.log(err)} />
                                </div> */}
                                {/* <div className="mx-auto" style={{ width: 118 }} >
                                    <AvatarUploader
                                        name="avatar"
                                        size={118}
                                        defaultImg={avatar2}
                                        uploadURL="http://localhost:3000"
                                        placeholder="string"
                                        fileType={"image/jpg"}
                                        onFinished={(err, res) => console.log(err)} />
                                </div> */}
                                <div className="avatar avatar-xxl avatar-rounded position-relative">
                                    <div className="d-flex flex-column justify-content-around border border-4 border-white rounded-circle w-100 h-100 bg-primary  text-center" >
                                        <h1 style={{ fontSize: '50px' }} className='text-white'>{AvatarLetters(data.fullName)}</h1>
                                    </div>
                                </div>
                                <div className="cp-name text-truncate mt-3">{data.fullName}</div>
                                {/* <p>No phone calls Always busy</p>
                                <Rating initialValue={3} readonly size="20" />
                                <ul className="hk-list hk-list-sm justify-content-center mt-2">
                                    <li>
                                        <Button variant="soft-primary" className="btn-icon btn-rounded">
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Mail />
                                                </span>
                                            </span>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button variant="soft-success" className="btn-icon btn-rounded">
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Phone />
                                                </span>
                                            </span>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button variant="soft-danger" className="btn-icon btn-rounded">
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Video />
                                                </span>
                                            </span>
                                        </Button>
                                    </li>
                                </ul> */}
                            </div>
                            <EditInfo reload={reload} setReload={setReload} info={data} show={editInfo} hide={() => setShowEditInfo(!editInfo)} />
                            <Card>
                                <Card.Header>
                                    <Link role='button'>Profile Information</Link>
                                    <Button variant="light" size="xs" className="btn-icon btn-rounded" onClick={() => setShowEditInfo(!editInfo)} >
                                        <HkTooltip placement="top" title="Edit" >
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Edit2 />
                                                </span>
                                            </span>
                                        </HkTooltip>
                                    </Button>
                                </Card.Header>
                                <Card.Body>

                                    <ul className="cp-info">
                                        <li>
                                            <span>Full name</span>
                                            <span>{data.fullName}</span>
                                        </li>

                                        <li>
                                            <span>Email</span>
                                            <span>{data.email}</span>
                                        </li>
                                        <li>
                                            <span>Phone</span>
                                            <span>{data.phone}</span>
                                        </li>

                                    </ul>

                                </Card.Body>
                            </Card>
                            <div className="separator-full" />
                            <Card>
                                <Card.Header>
                                    <Link to="#">Company Info</Link>
                                    <Button variant="light" size="xs" className="btn-icon btn-rounded" onClick={() => setShowEditInfo(!editInfo)} >
                                        <HkTooltip placement="top" title="Edit" >
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Edit2 />
                                                </span>
                                            </span>
                                        </HkTooltip>
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="cp-info">

                                        <li>
                                            <span>Company</span>
                                            <span>{data.companyName}</span>
                                        </li>


                                        <li>
                                            <span>Address</span>
                                            <span>{data.address}</span>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                            <div className="separator-full" />
                            <Card>
                                <Card.Header>
                                    <Link to="#">Operator Info</Link>
                                    {/* <Button variant="light" size="xs" className="btn-icon btn-rounded" onClick={() => setShowEditInfo(!editInfo)} >
                                        <HkTooltip placement="top" title="Edit" >
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Edit2 />
                                                </span>
                                            </span>
                                        </HkTooltip>
                                    </Button> */}
                                </Card.Header>
                                <Card.Body>
                                    <ul className="cp-info">

                                        <li>
                                            <span>Assign To</span>
                                            <span>{data.user.fullName}</span>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="contact-more-info w-lg-70 w-100">
                            <div className="pipeline-status-wrap mt-5">
                                <div className="title-lg mb-3">Lead Pipeline Status</div>
                                <ul className="pipeline-stutus">
                                    {console.log(data.companyLeadStage.order)}
                                    {stages.map((stage, index) => {
                                        { console.log(data.companyLeadStage.order, stage.order) }
                                        if (stages.length - 2 > index)
                                            return (
                                                <li className={`${data.companyLeadStage.order == stage.order ? 'active' : data.companyLeadStage.order > stage.order ? 'completed' : ''}`}><span>{stage.stageType}</span></li>
                                            )
                                    })}
                                    <li className={`${data.companyLeadStage.stageType == 'Won' ? 'won' : data.companyLeadStage.stageType == 'Lost' ? 'lost' : ''}`}><span>
                                        {`${data.companyLeadStage.stageType == 'Won' ? 'won' : data.companyLeadStage.stageType == 'Lost' ? 'lost' : 'Win/Loss'}`}
                                    </span></li>
                                </ul>
                                <div className="clearfix" />
                                <div className='d-flex justify-content-end'>
                                    <Button className='m-5' onClick={() => showUpdateStage(true)}>Update Stage</Button>
                                </div>
                            </div>
                            <UpdateStage stageId={data.companyLeadStage.id} leadId={data.id} reload={reload} setReload={setReload} show={updateStage} close={() => showUpdateStage(false)} />
                            <Tab.Container defaultActiveKey="activity" >
                                <Nav variant='tabs' className="nav-line nav-icon nav-light">
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="tab_summery">
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Zap />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Summery</span>
                                        </Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item>
                                        <Nav.Link eventKey="activity">
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Activity />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Activity</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="notes">
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Edit3 />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Notes</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="email">
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Mail />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Email</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="calls">
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Phone />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Calls</span>
                                        </Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item>
                                        <Nav.Link eventKey="tasks">
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <CheckSquare />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Tasks</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="schedule">
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Clock />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Schedule</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="sales">
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Shield />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Sales</span>
                                        </Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                                <Tab.Content className="mt-7">

                                    {/* <Tab.Pane eventKey="tab_summery">
                                        <Form>
                                            <Row>
                                                <Col md={12} as={Form.Group} className="mb-3" >
                                                    <div className="form-label-group">
                                                        <Form.Label>Write a Note</Form.Label>
                                                        <small className="text-muted">1200</small>
                                                    </div>
                                                    <Form.Control as="textarea" rows={8} placeholder="Write an internal note" />
                                                </Col>
                                            </Row>
                                            <Button variant="outline-light" className="mt-2">Add Note</Button>
                                        </Form>
                                    </Tab.Pane> */}


                                    <Tab.Pane eventKey="activity">
                                        <div className="activity-wrap mt-7">
                                            <div className="d-flex align-items-center justify-content-between mb-4">
                                                <div className="title-lg mb-0">Upcoming</div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mb-4">
                                                <div className="title-lg mb-0">Activity</div>
                                                <Form.Select className="mw-150p">
                                                    <option value={0}>All Activity</option>
                                                    <option value={1}>One</option>
                                                    <option value={2}>Two</option>
                                                    <option value={3}>Three</option>
                                                </Form.Select>
                                            </div>
                                            {data?.activity?.map((activity, index) => (
                                                <div>
                                                    {console.log(activity.date, data.activity?.[index + 1]?.date)}
                                                    {activity.date != data.activity?.[index - 1]?.date &&
                                                        <div className="title-sm text-primary mt-5 mb-3">{activity.date}</div>}
                                                    <ul className="activity-thread">
                                                        <li>
                                                            <div className="media">
                                                                <div className="media-head">
                                                                    {activity.type == 'email' ?
                                                                        <div className="avatar avatar-icon avatar-sm avatar-primary avatar-rounded">
                                                                            <span className="initial-wrap"><span className="feather-icon">
                                                                                <Mail />
                                                                            </span>
                                                                            </span>
                                                                        </div> :
                                                                        activity.type == 'note' ?
                                                                            <div className="avatar avatar-icon avatar-sm avatar-primary avatar-rounded">
                                                                                <span className="initial-wrap"><span className="feather-icon">
                                                                                    <Edit3 />
                                                                                </span>
                                                                                </span>
                                                                            </div> : <div className="avatar avatar-icon avatar-sm avatar-primary avatar-rounded">
                                                                                <span className="initial-wrap"><span className="feather-icon">
                                                                                    <CheckSquare />
                                                                                </span>
                                                                                </span>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div className="media-body">
                                                                    <div>
                                                                        <div className="activity-text"> {activity.desc} </div>
                                                                        <div className="activity-time">{activity.time}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            ))}
                                            {/* <div className="title-sm text-primary mb-3">June 24</div>
                                            <ul className="activity-thread">
                                                <li>
                                                    <div className="media">
                                                        <div className="media-head">
                                                            <div className="avatar avatar-icon avatar-sm avatar-primary avatar-rounded">
                                                                <span className="initial-wrap"><span className="feather-icon">
                                                                    <Mail />
                                                                </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="activity-text">You sent <span className="text-dark text-capitalize">1 message</span> to the contact.</div>
                                                                <div className="activity-time">10.00 pm</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="media">
                                                        <div className="media-head">
                                                            <div className="avatar avatar-sm avatar-soft-danger avatar-rounded">
                                                                <span className="initial-wrap">M</span>
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="activity-text"><span className="text-dark text-capitalize">Morgan Freeman</span> as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled.</div>
                                                                <div className="activity-time">10.00 pm</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="media">
                                                        <div className="media-head">
                                                            <div className="avatar  avatar-icon avatar-sm avatar-info avatar-rounded">
                                                                <span className="initial-wrap"><span className="feather-icon">
                                                                    <Shield />
                                                                </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="activity-text">Your deal value <span className="text-dark">$208.15</span> is paid through PayU Money online on <span className="text-dark">02.12.18</span> at <span className="text-dark">15:30, Monday</span></div>
                                                                <div className="activity-time">10.00 pm</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul> */}


                                            {/* <div className="title-sm text-primary mt-5 mb-3">June 25</div>
                                            <ul className="activity-thread">
                                                <li>
                                                    <div className="media">
                                                        <div className="media-head">
                                                            <div className="avatar avatar-sm avatar-soft-danger avatar-rounded">
                                                                <span className="initial-wrap">M</span>
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="activity-text"><span className="text-dark">Morgan Freeman</span> responded to your appointment schedule question. </div>
                                                                <div className="activity-time">10.00 pm</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul> */}
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="notes">
                                        <Notes setAlertData={setAlertData} setAlertShow={setAlertShow} setReload={setReload} leadId={data.id} notes={data.leadNotes} />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey='tasks'>
                                        <TaskList tasks={data.leadTasks} />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey='schedule'>
                                        <Schedule leadId={data.id} tasks={data.leadTasks} reload={reload} setReload={setReload} />
                                    </Tab.Pane>

                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </SimpleBar>
            </div>

            {/* Add Tag */}
            <AddTags show={addTags} hide={() => setAddTags(!addTags)} />
            {/* Profile Information */}
            {/* <ProfileInfo show={editInfo} hide={() => setShowEditInfo(!editInfo)} /> */}
            {/* More Info */}
            <MoreInfo show={moreInfo} hide={() => setMoreInfo(!moreInfo)} />
            {/* Add Bio */}
            <AddBio show={addBio} hide={() => setAddBio(!addBio)} />

        </>
    )
}

export default EditLeadBody;
