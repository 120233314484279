import React, { useCallback, useMemo } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HkBadge from '../../../components/@hk-badge/@hk-badge';
import HkTooltip from '../../../components/@hk-tooltip/HkTooltip';

//Image 
import avatar3 from '../../../assets/dist/img/avatar3.jpg';
import { useSelector } from 'react-redux';
import AvatarLetters from '../../../utils/AvatarLetters';

const ProfileIntro = () => {
    const userDetails = useSelector(state => state.userReducer)
    const avatarLetters = useMemo(() => AvatarLetters(userDetails.data.fullName), [userDetails.data.fullName])
    return (
        <div className="profile-intro">
            <Card className="card-flush mw-400p bg-transparent">
                <Card.Body>
                    <div className="avatar avatar-xxl avatar-rounded position-relative mb-2">
                        <div className="d-flex flex-column justify-content-around border border-4 border-white rounded-circle w-100 h-100 bg-primary  text-center" >
                            <h1 style={{ fontSize: '50px' }} className='text-white'>{avatarLetters}</h1>
                        </div>
                    </div>
                    <h4>{userDetails.data.fullName}

                    </h4>
                    <p>
                        {userDetails.data.email}
                    </p>
                    <ul className=" mt-2 mb-0">
                        <li className="mb-sm-0 mb-1 me-3">
                            <p><span>Role : </span>{userDetails.data.roleType === 1 ? 'Admin' : 'Operator'}</p>
                        </li>
                        <li className="mb-sm-0 mb-1 me-3">
                            <p><span>Phone : </span>{userDetails.data.phone}</p>
                        </li>
                    </ul>
                    <Button variant='primary mt-3' onClick={()=>{
                        localStorage.removeItem('token')
                        window.location.replace('/auth/send-resetmail')
                    }}>Change Password</Button>
                </Card.Body>
            </Card>
        </div>
    )
}

export default ProfileIntro
