import { GET_TASKS_FULFILLED,GET_TASKS_PENDING,GET_TASKS_REJECTED } from "../constants/Task"
const initialState = {
    data: {
        tasks: [],
    }
}
export function TaskReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TASKS_FULFILLED:
            return { ...state, data: action.payload, pending: false }
        case GET_TASKS_REJECTED:
            return { ...state, error: action.error, pending: false }
        case GET_TASKS_PENDING:
            return { ...state, pending: true }
        default:
            return state
    }
}