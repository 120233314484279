import { AnimatePresence } from 'framer-motion'
import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PageAnimate from '../components/Animation/PageAnimate'
import LayoutHorizontal from '../layout/MainLayout/HorizontalLayout'
import { allRoutes } from './RouteList'
import { useDispatch } from 'react-redux'
import { getUserDetails } from '../redux/action/UserDetails'
import { useEffect } from 'react'
import { getStages } from '../redux/action/Stages'

const AllRoutes = (props) => {

    const { match } = props;
    const dispatch = useDispatch()
    useEffect(() => {
      dispatch(getUserDetails)
      dispatch(getStages)
    }, [])
    return (
        <>
            <AnimatePresence>
                <Suspense
                    fallback={
                        <div className="preloader-it">
                            <div className="loader-pendulums" />
                        </div>
                    }>
                    <LayoutHorizontal>
                        <Switch>

                            {
                                allRoutes.map((obj, i) => {
                                    return (obj.component) ? (
                                        <Route
                                            key={i}
                                            exact={obj.exact}
                                            path={match.path + obj.path}
                                            render={matchProps => (
                                                <>
                                                    <PageAnimate>
                                                        <obj.component {...matchProps} />
                                                    </PageAnimate>

                                                </>
                                            )}
                                        />) : (null)
                                })
                            }
                            <Route path="*">
                                <Redirect to="/error/error-404" />
                            </Route>
                        </Switch>
                    </LayoutHorizontal>
                </Suspense>
            </AnimatePresence>
        </>
    )
}

export default AllRoutes
