import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

const AlertMsg = ({data,show,setShow}) => {
    return (
        <div className='d-flex  justify-content-center'>
            <Alert className='w-50' show={show} variant={data.variant} onClose={() => setShow(false)} dismissible>
                <Alert.Heading>{data.title}</Alert.Heading>
                <p className={`text-${data.variant}`}>{data.msg}</p>
            </Alert>
        </div>
    )
}

export default AlertMsg
