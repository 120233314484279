import React from 'react';
import { Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CommonFooter from '../CommonFooter';

//image
import logo from '../../../assets/dist/img/logo-light.png';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import RegexUtils from '../../../utils/RegexUtils';
import axios from 'axios';
import AlertMsg from '../../../utils/Alert';
import { api } from '../../../api';

const SendResetMail = () => {
    // const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit,
    } = useForm({ criteriaMode: "all", mode: "all" });
    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
    const [getButton, setGetButton] = useState(true)
    const handleReset = async () => {
        setGetButton(false)
        try {
            setLoading(true)
            const { data } = await api.post('/forgot-password', {
                email: getValues('email')
            })
            console.log(data)
            const details = {
                variant: 'success',
                title: 'Success',
                msg: data.message
            }
            setAlertData(details)
            setAlertShow(true)
        } catch (error) {
            console.log(error)
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)
            setGetButton(true)

        }
        setLoading(false)
    }

    return (
        <div className="hk-pg-wrapper pt-0 pb-xl-0 pb-5">
            <div className="hk-pg-body pt-0 pb-xl-0">

                <Container>
                    <div className='mt-2'>
                        <AlertMsg data={alertData} show={showAlert} setShow={setAlertShow} />

                    </div>
                    <Row>
                        <Col sm={10} className="position-relative mx-auto">
                            <div className="auth-content py-8">
                                <Form className="w-100" onSubmit={handleSubmit(handleReset)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            // e.preventDefault();
                                            handleSubmit(handleReset)
                                        }
                                    }}>
                                    <Row>
                                        <Col lg={5} md={7} sm={10} className="mx-auto">
                                            <div className="text-center mb-7">
                                                <Link to="/" className="navbar-brand me-0">
                                                    <img className="brand-img d-inline-block" src={logo} alt="brand" />
                                                </Link>
                                            </div>
                                            <Card className="card-flush">
                                                <Card.Body className="text-center">
                                                    <h4>Reset your Password</h4>
                                                    <p className="mb-4">Enter your email address to receive a password reset link. Once you click the link sent to your email, you can proceed to reset your password.</p>
                                                    <Row className="gx-3">
                                                        <Col lg={12} as={Form.Group} className="mb-3">
                                                            <div className="form-label-group">
                                                                <Form.Label htmlFor="userName">Email</Form.Label>
                                                                {/* <Link to="#" className="fs-7 fw-medium">Forgot Username ?</Link> */}
                                                            </div>
                                                            <Form.Control  {...register("email", {
                                                                required: "Email is required",
                                                                pattern: {
                                                                    value: RegexUtils.EMAIL,
                                                                    message: "Email  is invalid",
                                                                },
                                                            })}
                                                                // value={email}
                                                                onChange={e => {
                                                                    // setEmail(e.target.value)
                                                                    setGetButton(true)
                                                                }}
                                                                placeholder="Enter your email" type="email" />
                                                            {errors.email && <p className='text-danger mt-1'>Email is invalid</p>}

                                                        </Col>
                                                    </Row>
                                                    {loading &&
                                                        <div className='d-flex justify-content-center mb-2'>
                                                            <Spinner animation="border" variant="primary" />
                                                        </div>
                                                    }
                                                    <Button variant="primary" disabled={!getButton} type='submit' className="btn-uppercase btn-block">Get Link</Button>
                                                    <p className="p-xs mt-2 text-center">Did not receive link? <Link to="#"><u onClick={handleSubmit(handleReset)}>Send again</u></Link></p>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Page Footer */}
            <CommonFooter />
        </div>

    )
}

export default SendResetMail
