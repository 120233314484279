import React from 'react'
import Body from './Body'

const Notes = ({ setAlertData, setAlertShow, setReload, leadId, notes }) => {
  return (
    <div className='mb-5'>
      <Body setAlertData={setAlertData} setAlertShow={setAlertShow}  setReload={setReload} leadId={leadId} notes={notes}  />
    </div>
  )
}

export default Notes
