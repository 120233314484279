import React from 'react';
import classNames from 'classnames';
import { Button, Dropdown, Form, Table } from 'react-bootstrap';
import { ChevronDown, ChevronRight, MoreVertical, Star } from 'react-feather';
// import HkBadge from '../../../components/@hk-badge/@hk-badge';
import HkBadge from '../../../../../components/@hk-badge/@hk-badge';
import { useState } from 'react';
import ReactShowMoreText from 'react-show-more-text';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import DateFormatter from '../../../../../utils/DateFormatter';
import HkTooltip from '../../../../../components/@hk-tooltip/HkTooltip';
import dateFormatter from '../../../../../utils/DateFormatter';
import timeFormatter from '../../../../../utils/TimerFormatter';

const Task = ({ task }) => {
    const [selected, setSelected] = useState(task.isCompleted)
    const [startDate, setStartDate] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);
    const handleChange = (e) => {
        setIsOpen(!isOpen);
        console.log(e)
        setStartDate(e);
    };
    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };
    const [arrow,setArrow] = useState(false)
    return (


        <>

            <div className="collapse-simple mt-3 border shadow-xl rounded-5 px-3 pt-2">
                <Card>
                    <div >
                        <Card.Header className='d-flex flex-row p-0 border-0'>
                            <Link className='w-80 m-0' role="button" onClick={()=>setArrow(!arrow)} data-bs-toggle="collapse" to={`#${'task' + task.id}`} aria-expanded="true">
                                <h5 className="">
                                        <span className="feather-icon me-2 text-light">
                                            {arrow ? <ChevronRight/> : <ChevronDown/>}
                                        </span>
                                    {task.title}
                                </h5>


                            </Link>
                            <div className='d-flex w-20 m-0 justify-content-end'>
                                <h6 className='mt-2 w-70 text-end text-primary'>{dateFormatter(task.startDate,'dd-MMM-yy','-') + '  ' + timeFormatter(task.startDate,'hh:mm',':',true)}</h6>
                                <Dropdown className='m-0 w-30'>
                                    <Dropdown.Toggle variant='flush-light' className="btn-icon btn-rounded flush-soft-hover no-caret">
                                        <span className="icon">
                                            <span className="feather-icon">
                                                <MoreVertical />
                                            </span>
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu  >
                                        <Dropdown.Item className="edit-task">Edit Task</Dropdown.Item>
                                        <Dropdown.Item className="view-task">View Task</Dropdown.Item>
                                        <Dropdown.Item className="delete-task" >Delete Task</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Card.Header>

                    </div>

                    <div id={'task' + task.id} className="collapse hide ">
                        <Card.Body>
                            <div className="separator-full mt-0" />
                            <div className='d-flex'>
                                <Form.Check className='me-1'>
                                    <Form.Check.Input
                                        type="checkbox"
                                        readOnly
                                        checked={selected}
                                        // onChange={() => setSelected(s => !s)}
                                    />
                                    <Form.Check.Label />
                                </Form.Check>
                                <ReactShowMoreText
                                    lines={2}
                                    more="more"
                                    less="less"
                                    className="content-css text-dark w-100 mx-2"
                                    anchorClass="show-more-less-clickable"
                                    expanded={false}
                                    truncatedEndingComponent={"... "}
                                >
                                    {task.description}
                                </ReactShowMoreText>
                            </div>
                            <Table bordered className='text-center mt-3 shadow'>
                                <thead>
                                    <tr>
                                        <th>Reminder</th>
                                        <th>Priority</th>
                                        <th>Assign To</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>No Reminder</td>
                                        <td> <HkBadge
                                            indicator
                                            bg={task.priorityId === 3 ? 'danger' : task.priorityId === 2 ? 'warning' : 'gold'}
                                            className="badge-indicator-xl d-md-inline-block d-none me-1"
                                        />{task.priorityId === 3 ? 'High' : task.priorityId === 2 ? 'Medium' : 'Low'}</td>
                                        <td>{task.user.fullName}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            {/* <li
                                className={classNames("mb-3 advance-list-item single-task-list active-todo", { "selected": selected })}
                                key={1}
                            >
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center me-1 w-100">
                                        <Form.Check className='me-1'>
                                            <Form.Check.Input
                                                type="checkbox"
                                                checked={selected}
                                                onChange={() => setSelected(s => !s)}
                                            />
                                            <Form.Check.Label />
                                        </Form.Check>
                                        <span className={classNames("todo-star me-1", { " marked": false })}>
                                            <span className="feather-icon">
                                                <Star />
                                            </span>
                                        </span>
                                        {console.log(task.priority)}

                                        <HkBadge
                                            indicator
                                            bg={task.priority === 1 ? 'danger' : task.priority === 2 ? 'warning' : 'gold'}
                                            className="badge-indicator-xl d-md-inline-block d-none"
                                        />
                                        <ReactShowMoreText
                                            lines={2}
                                            more="more"
                                            less="less"
                                            className="content-css text-dark w-100 ms-2"
                                            anchorClass="show-more-less-clickable"
                                            expanded={false}
                                            truncatedEndingComponent={"... "}
                                        >
                                            {task.task}
                                        </ReactShowMoreText>


                                    </div>


                                    <div className="d-flex flex-shrink-0  align-items-top ">
                                        <span>{task.deadline}</span>

                                        <Dropdown>
                                            <Dropdown.Toggle variant='flush-light' className="btn-icon btn-rounded flush-soft-hover no-caret">
                                                <span className="icon">
                                                    <span className="feather-icon">
                                                        <MoreVertical />
                                                    </span>
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu align="end" >
                                                <Dropdown.Item className="edit-task">Edit Task</Dropdown.Item>
                                                <Dropdown.Item className="view-task">View Task</Dropdown.Item>
                                                <Dropdown.Item className="delete-task"  >Delete Task</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </li> */}


                        </Card.Body>
                    </div>
                </Card>
            </div>


        </>

    )
}

export default Task
