import React, { useState } from 'react';
import EmployeeTableBody from './EmployeeTableBody';
import EmployeesListHeader from './EmployeesListHeader';
import { useSelector } from 'react-redux';
import { useHistory,useLocation } from 'react-router-dom'
const EmployeeList = () => {
    const [reload, setReload] = useState(false)
    const userDetails = useSelector(state => state.userReducer.data)
    const location = useLocation();
    const currentPath = location.pathname;
    const history = useHistory()
    if (userDetails.roleType === 1 && currentPath === '/app/employees/employee-list' )
        return history.replace('/app/setting')
        return (
            <div className="contactapp-detail-wrap">
                <EmployeesListHeader reload={reload} setReload={setReload} />
                <EmployeeTableBody reload={reload} setReload={setReload} />
            </div>


        )
}

export default EmployeeList
