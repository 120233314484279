import React from 'react'
import AlertMsg from '../../../utils/Alert'
import { useState } from 'react'
import {useHistory,useLocation} from 'react-router-dom'
import { useEffect } from 'react'
import { api } from '../../../api'
const EditEmployee = () => {
    const [data, setData] = useState({})
    const [reload, setReload] = useState(false)
    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!location.state || !location.state.data) return history.replace('/');
    const fetchData = async () => {
      try {
          let { data } = await api.get('/admin/user/' + location.state.data.id)
          console.log(data.data)
          setData(data.data)
      } catch (error) {
          console.log(error)
          console.log(error)
          let details
          error?.response?.data?.message ? details = {
              variant: 'danger',
              title: 'Error',
              msg: error.response.data.message
          } : details = {
              variant: 'danger',
              title: 'Error',
              msg: "Something went wrong try later"
          }
          setAlertData(details)
          setAlertShow(true)
      }
  }
  fetchData()
  
  }, [location.state,reload]);
  return (
    <div>
           <AlertMsg data={alertData} show={showAlert} setShow={setAlertShow} />
           <div>{data.fullName}</div>
           <div>{data.email}</div>
           <div>{data.phone}</div>
           <div>{data.roleType == 1 ? 'Admin' : 'Operator'}</div>

    </div>
  )
}

export default EditEmployee
