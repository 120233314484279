import React, { useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Plus } from 'react-feather';
import { Link } from 'react-router-dom';
import HkTooltip from '../../../../components/@hk-tooltip/HkTooltip';
import { useForm } from 'react-hook-form';
import axios from 'axios'
import { useState } from 'react';
import AlertMsg from '../../../../utils/Alert';
import Select from 'react-select';
import { LocalTimeConverter } from '../../../../utils/LocalTimeConverter';
import { api } from '../../../../api'
import { useSelector } from 'react-redux';
import Confirm from '../../../../utils/Confirm';
const AddNewTask = ({ pastDateValidator, dateDifferenceValidator, leadId, isUpdate, taskData, show, hide, reload, setReload }) => {

    const hideCalender = (ev, picker) => {
        picker.container.find(".calendar-table").hide();
    };
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
    const [showAlert, setAlertShow] = useState(false)
    const [showDeleteTask, setShowDeleteTask] = useState(false)
    const {
        register: registerTask,
        formState: { errors: taskErrors },
        getValues: getTaskValues,
        setValue: setTaskValues,
        handleSubmit: handleTaskSubmit
    } = useForm({ criteriaMode: "all", mode: "all" });
    const [assignToError, setAssignToError] = useState(false)
    const [employeesOptions, setEmployeesOptions] = useState([])
    // const employeesOptions = [
    //     { value: 'employee1', label: 'Employee1', id: 1 },
    //     { value: 'employee2', label: 'Employee2', id: 2 },
    //     { value: 'employee3', label: 'Employee3', id: 3 },
    //     { value: 'employee4', label: 'Employee4', id: 4 },
    //     { value: 'employee5', label: 'Employee5', id: 5 },
    //     { value: 'employee6', label: 'Employee6', id: 6 },
    //     { value: 'employee7', label: 'Employee7', id: 7 },
    //     { value: 'employee8', label: 'Employee8', id: 8 },
    //     { value: 'employee9', label: 'Employee9', id: 9 },
    //     { value: 'employee10', label: 'Employee10', id: 10 }
    // ];
    useEffect(() => {
        const fetchEmployees = async () => {
            try {


                const { data } = await api.get('/admin/users')
                let employees = data.data.map((employee) => {
                    return {
                        value: employee.fullName,
                        label: employee.fullName,
                        id: employee.id
                    }
                })
                setEmployeesOptions(employees)
            } catch (error) {
                console.log(error)
                let details
                error?.response?.data?.message ? details = {
                    variant: 'danger',
                    title: 'Error',
                    msg: error.response.data.message
                } : details = {
                    variant: 'danger',
                    title: 'Error',
                    msg: "Something went wrong try later"
                }
                setAlertData(details)
                setAlertShow(true)

            }
        }
        fetchEmployees()
    }, [])
    const [assignTo, setAssignTo] = useState(null);
    const [title, setTitle] = useState('')
    const [description, setDesc] = useState('')
    const [priority, setPriority] = useState(3)
    const [startDate, setStart] = useState()
    const [endDate, setEnd] = useState()
    const [isCompleted, setCompleted] = useState(false)
    useEffect(() => {
        console.log(taskData)
        setTaskValues('title', taskData.title)
        setTaskValues('description', taskData.description)
        setPriority(taskData.priority)
        setTaskValues('startDate', taskData.startDate)
        setTaskValues('endDate', taskData.endDate)
        setCompleted(taskData.isCompleted)
        setAlertShow(false)
        setAssignTo({ value: taskData.assignTo.value, label: taskData.assignTo.label, id: taskData.assignTo.id })
    }, [taskData])
    const createTask = async () => {
        try {
            console.log(assignTo?.id)
            if (!assignTo?.id) return setAssignToError(true)
            if (dateDifferenceValidator(getTaskValues('startDate'), getTaskValues('endDate'))) {
                let details = {
                    variant: 'danger',
                    title: 'Invalid range',
                    msg: 'Select Valid Range of Start and End Date and time'
                }
                setAlertData(details)
                return setAlertShow(true)

            }

            const newTask = {
                leadId,
                title: getTaskValues('title'),
                description: getTaskValues('description') || '',
                priority: Number(getTaskValues('priority')) === 1 ? 'LOW' : Number(getTaskValues('priority')) === 2 ? 'MEDIUM' : 'HIGH',
                startDate: getTaskValues('startDate'),
                endDate: getTaskValues('endDate'),
                isCompleted,
                assignedTo: assignTo.id
            }
            if (!isUpdate) {
                const { data } = await api.post(`/leads/tasks/add`, newTask)
                console.log(data)
            } else {
                const { data } = await api.patch(`/leads/tasks/` + leadId, newTask)
                console.log(data)
            }



            let details = {
                variant: 'success',
                title: isUpdate ? "Updated" : "Added",
                msg: isUpdate ? "Task updated" : "Task added"
            }
            setAlertData(details)
            setAlertShow(true)
            setReload(!reload)
        } catch (error) {
            console.log(error)
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)

        }
    }
    const deleteTask = async () => {
        try {
            const { data } = await api.delete(`/leads/tasks/` + taskData.id)
            console.log(data)
            let details = {
                variant: 'success',
                title: "Task Deleted",
                msg: "Task Deleted Successfully"
            }
            setAlertData(details)
            setAlertShow(true)
            setReload(!reload)
            hide()
        } catch (error) {
            console.log(error)
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)
        }
        setShowDeleteTask(false)
    }
    return (
        <Modal size='lg' show={show} onHide={hide} centered className="add-new-contact">
            <Modal.Body>
                <Button bsPrefix="btn-close" onClick={hide} >
                    <span aria-hidden="true">×</span>
                </Button>
                <Modal.Title as="h5" className="mb-4">{isUpdate ? 'Update Task' : 'Add New Task'}</Modal.Title>
                <div className='w-100'>
                    <AlertMsg data={alertData} show={showAlert} setShow={setAlertShow} />
                </div>
                <Form onSubmit={
                    handleTaskSubmit(createTask)
                }
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleTaskSubmit(createTask);
                        }
                    }}>
                    <div className="title title-xs title-wth-divider text-primary text-uppercase mt-1 mb-4"><span>Basic Info</span></div>
                    <Row className="gx-3">
                        <Col sm={8}>
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    // defaultValue={title}
                                    // onChange={(e) => {
                                    //     setTaskValues('title', e.target.value);
                                    // }}
                                    {...registerTask("title", {
                                        required: "task title is required"
                                    })}
                                    type="text" />
                                {taskErrors.title && <p className='text-danger mt-1'>Task Name is required</p>}

                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="gx-3">
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <div className="form-label-group">
                                    <Form.Label>Task/Description</Form.Label>
                                    {/* <small className="text-muted">200</small> */}
                                </div>
                                <Form.Control as="textarea" rows={3}
                                    // defaultValue={description}
                                    // onChange={(e) => {
                                    //     setTaskValues('description', e.target.value);
                                    // }}
                                    {...registerTask("description")}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="gx-3">
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Start</Form.Label>
                                <Form.Control

                                    type="datetime-local"
                                    min={LocalTimeConverter(new Date())}
                                    // defaultValue={getTaskValues('startDate')}
                                    // onChange={(e) => {
                                    //     setTaskValues('startDate', e.target.value);
                                    // }}
                                    {...registerTask("startDate", {
                                        required: "Start date n time is required"
                                    })}
                                />
                                {taskErrors.startDate && <p className='text-danger mt-1'>Start Date and Time is required</p>}
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>End</Form.Label>
                                <Form.Control type="datetime-local"
                                    // defaultValue={getTaskValues('endDate')}
                                    // onChange={(e) => {
                                    //     setTaskValues('endDate', e.target.value);
                                    // }}
                                    min={LocalTimeConverter(new Date())}
                                    {...registerTask("endDate", {
                                        required: "endDate time is required"
                                    })}
                                />
                                {taskErrors.endDate && <p className='text-danger mt-1'>End Date and Time is required</p>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="gx-3">

                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Completed</Form.Label>
                                <Form.Check type="checkbox" className="form-check-sm mt-2">
                                    <Form.Check.Input type="checkbox"
                                        checked={isCompleted}
                                        onChange={() => setCompleted(!isCompleted)}
                                    />
                                    <Form.Check.Label>Is Completed</Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className='block'>Set priority</Form.Label>
                                <div>
                                    <Form.Check inline type="radio" className="" id="customRadioc2">
                                        <Form.Check.Input type="radio" name="customRadioc2"
                                            {...registerTask("priority")} value={3} checked={priority == 3} onChange={() => setPriority(3)} />
                                        <Form.Check.Label htmlFor="customRadioc2">High</Form.Check.Label>
                                    </Form.Check>
                                    <Form.Check inline type="radio" id="customRadioc3">
                                        <Form.Check.Input type="radio" name="customRadioc2"
                                            {...registerTask("priority")} value={2} checked={priority == 2} onChange={() => setPriority(2)}
                                        />
                                        <Form.Check.Label htmlFor="customRadioc3">Medium</Form.Check.Label>
                                    </Form.Check>
                                    <Form.Check inline type="radio" id="customRadioc4">
                                        <Form.Check.Input type="radio" name="customRadioc2"
                                            {...registerTask("priority")} value={1} checked={priority == 1} onChange={() => setPriority(1)} />
                                        <Form.Check.Label htmlFor="customRadioc4">Low</Form.Check.Label>
                                    </Form.Check>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* <Row className="gx-3">
                        
                    </Row> */}
                    <div className="title title-xs title-wth-divider text-primary text-uppercase my-4"><span>Assign to</span></div>
                    <div className="repeater">
                        <div data-repeater-list="category-group">
                            <div className="d-flex">
                                <Row className="gx-3 flex-1">
                                    <Col sm={12}>
                                        <div className='mb-5'>
                                            <Select
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderColor: '#032390',
                                                        boxShadow: state.isFocused ? null : null,
                                                    }),
                                                    menuList: (provided, state) => ({
                                                        ...provided,
                                                        height: '80px',
                                                    }),

                                                }}
                                                defaultValue={assignTo}
                                                value={assignTo}
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isRtl={false}
                                                isSearchable={true}
                                                name="assignTo"
                                                options={employeesOptions}
                                                onChange={(selectedOption) => {
                                                    setAssignTo(selectedOption);
                                                    setAssignToError(false)
                                                }}
                                            />
                                            {assignToError && <p className='text-danger mt-1'>Person Name is required</p>}

                                        </div>
                                        {/* <Form.Group className="mb-3">
                                            <Form.Label>Add person</Form.Label>
                                            <Form.Control
                                                {...registerTask("assignTo", {
                                                    required: "person name is required"
                                                })}
                                                type="text" />
                                            {taskErrors.assignTo && <p className='text-danger mt-1'>Person Name is required</p>}

                                        </Form.Group> */}
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </div>
                    <div className='mt-8'>
                        <Confirm show={showDeleteTask} onHide={() => setShowDeleteTask(false)}
                            title={'Delete Task'}
                            desc={'Do You Really Want to Delete This Task'}
                            okTextVariant={'danger'}
                            okText={'Delete'}
                            cancelText={'Cancel'}
                            callBack={deleteTask} />
                    </div>
                    <Modal.Footer className="align-items-center">
                        <Button variant={isUpdate ? "danger" : "secondary"} onClick={isUpdate ? () => setShowDeleteTask(!showDeleteTask) : hide}>{isUpdate ? 'Delete' : 'Close'}</Button>
                        <Button variant="primary" type='submit'>{isUpdate ? 'Update' : 'Add'}</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>

        </Modal>
    )
}

export default AddNewTask
