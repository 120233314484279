import React, { useEffect, useState } from 'react';
import Body from './Body';
import Task from './Task';

const TaskList = ({tasks}) => {

   

    return (

        <div className='mb-5'>
            <Body tasks={tasks} />
        </div>


    )
}

export default TaskList
