import React, { useState } from 'react'
import Body from './Body'
import { Button } from 'react-bootstrap'
import AddNewTask from './AddNewTask'
import { LocalTimeConverter } from '../../../../utils/LocalTimeConverter'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'

const Schedule = ({ leadId, tasks, reload, setReload }) => {
  const [addNewTask, setAddNewTask] = useState(false);
  const userDetails = useSelector(state => state.userReducer)
  const [taskData, setTaskData] = useState({
    id: null,
    title: '',
    description: '',
    priority: 3,
    startDate: LocalTimeConverter(new Date()),
    endDate: LocalTimeConverter(new Date()),
    isCompleted: false,
    assignTo: { value: userDetails.data.fullName, label: userDetails.data.fullName, id: userDetails.data.id }
  })
  const [isUpdate, setIsUpdate] = useState(false)
  const pastDateValidator = useMemo(() => {
    return (date) => new Date(date) > new Date();
  }, []);
  const dateDifferenceValidator = useMemo(() => {
    return (date1, date2) => new Date(date1) >= new Date(date2)
  }, [])
  return (
    <div>
      <Button variant="primary" className="btn-rounded w-40 float-right  btn-block mb-4" onClick={() => {
        setTaskData({
          id: null,
          title: '',
          description: '',
          priority: 3,
          startDate: LocalTimeConverter(new Date()),
          endDate: LocalTimeConverter(new Date()),
          isCompleted: false,
          assignTo: { value: userDetails.data.fullName, label: userDetails.data.fullName, id: userDetails.data.id }
        })
        setIsUpdate(false)
        setAddNewTask(!addNewTask);
      }}>Add Task</Button>
      <AddNewTask pastDateValidator={pastDateValidator} dateDifferenceValidator={dateDifferenceValidator} leadId={leadId} isUpdate={isUpdate} tasks={tasks} reload={reload} setReload={setReload} show={addNewTask} hide={() => setAddNewTask(false)} taskData={taskData} />
      <Body pastDateValidator={pastDateValidator} leadId={leadId} setIsUpdate={setIsUpdate} tasks={tasks} setAddNewTask={setAddNewTask} setTaskData={setTaskData} />
    </div>
  )
}

export default Schedule
