import React, { useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import { Button, Col, Form, Pagination, Row } from 'react-bootstrap';
import { Star } from 'react-feather';
//Bootstrap Table Library
import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import paginationFactory, { PaginationProvider, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
//Table data 
import { columns } from './TableData';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import AlertMsg from '../../../utils/Alert';
import { useSelector } from 'react-redux';
import { api } from '../../../api';
import { useRef } from 'react';
//Table search
const { SearchBar } = Search;

const EmployeeTableBody = ({ reload, setReload }) => {
    const history = useHistory()
    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    //Custom Total layout
    const customTotal = (from, to, size) => (
        <span className="ms-2">
            {from} - {to} of {size}
        </span>
    );

    //Custom Select Box
    const selectRow = {
        mode: 'checkbox',
        selectionHeaderRenderer: ({ mode, indeterminate, ...rest }) => (
            <Form.Check
                type={mode}
                className="fs-6 mb-0"
                ref={(input) => {
                    if (input) input.indeterminate = false;
                }}
                {...rest}
                readOnly
            />
        ),
        selectionRenderer: ({ mode, checked }) => (
            <div className="d-flex align-items-center">
                <Form.Check type={mode} checked={checked} readOnly />

            </div>
        ),
        style: { backgroundColor: '#ebf5f5', color: "black" }

    };

    //Options for Table Paginations
    const handleNextPage = ({
        page,
        onPageChange
    }) => () => {
        onPageChange(page + 1);
    }

    const handlePrevPage = ({
        page,
        onPageChange
    }) => () => {
        onPageChange(page - 1);
    }
    const userDetails = useSelector(state => state.userReducer.data)
    const options = {
        custom: true,
        paginationSize: 4,
        pageStartIndex: 1,
        withFirstAndLast: false,
        sizePerPage: 10,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        totalSize: columns(userDetails.roleType).length
    };
    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
    const [data, setData] = useState([])
    const employeeType = useRef(1)
    useEffect(() => {
        const fetchData = async () => {
            try {
                let { data } = await api.get('/admin/users')
                console.log(data.data)
                setData(data.data)
            } catch (error) {
                console.log(error)
                let details
                error?.response?.data?.message ? details = {
                    variant: 'danger',
                    title: 'Error',
                    msg: error.response.data.message
                } : details = {
                    variant: 'danger',
                    title: 'Error',
                    msg: "Something went wrong try later"
                }
                setAlertData(details)
                setAlertShow(true)
            }
        }
        fetchData()
    }, [reload])

    const getEmployeeType = (e) => {
        employeeType.current = e.target.value
        setReload(r=>!r)
        // alert(employeeType.current)
    }
    return (
        <div className="contact-body">
            <AlertMsg data={alertData} show={showAlert} setShow={setAlertShow} />
            <SimpleBar className="nicescroll-bar">
                <div className="collapse" id="collapseQuick">
                    <div className="quick-access-form-wrap">
                        <Form className="quick-access-form border">
                            <Row className="gx-3">
                                <Col xxl={10}>
                                    <div className="position-relative">
                                        <div className="dropify-square">
                                            <input type="file" className="dropify-1" />
                                        </div>
                                        <Col md={12}>
                                            <Row className="gx-3">
                                                <Col lg={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control placeholder="First name*" type="text" />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control placeholder="Last name*" type="text" />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control placeholder="Email Id*" type="text" />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control placeholder="Phone" type="text" />
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                        </Col>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="contact-list-view">
                    <ToolkitProvider
                        keyField="id"
                        columns={columns(userDetails.roleType)}
                        data={data}
                        search
                    >
                        {props => (
                            <>

                                <PaginationProvider
                                    pagination={paginationFactory(options)}
                                >
                                    {
                                        ({
                                            paginationProps,
                                            paginationTableProps
                                        }) => (
                                            <>
                                                <Row className="mb-3" >
                                                    <Col xs={7} mb={3}>
                                                        <div className="contact-toolbar-left">


                                                            <Button size="sm" className="d-flex align-items-center">
                                                                Export to CSV
                                                            </Button>
                                                            <Form.Group className="d-xxl-flex ms-5  align-items-center mb-0">
                                                                <Form.Select onChange={(e) => getEmployeeType(e)} size='sm' className="w-130p">
                                                                    <option value={1}>Active</option>
                                                                    <option value={2}>Win</option>
                                                                    <option value={3}>Lost</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col xs={5} mb={3}>
                                                        <div className="contact-toolbar-right">
                                                            <div className="dataTables_filter">
                                                                <Form.Label>
                                                                    <Form.Control as={SearchBar} {...props.searchProps} size="sm" type="search" placeholder="Search" />
                                                                </Form.Label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <BootstrapTable
                                                    keyField='id'
                                                    data={data}
                                                    columns={columns(userDetails.roleType)}
                                                    selectRow={selectRow}
                                                    bordered={false}
                                                    wrapperClasses="table-responsive"
                                                    classes="nowrap w-100 mb-5"
                                                    {...paginationTableProps}
                                                    defaultSorted={defaultSorted}
                                                    {...props.baseProps}
                                                />

                                                <Row>
                                                    <Col>
                                                        <PaginationTotalStandalone
                                                            {...paginationProps}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Pagination className='custom-pagination pagination-simple d-flex justify-content-end'>
                                                            <Pagination.Prev onClick={handlePrevPage(paginationProps)} disabled={paginationProps.page === 1} > <i className="ri-arrow-left-s-line" /> </Pagination.Prev>
                                                            <Pagination.Item active>{paginationProps.page}</Pagination.Item>
                                                            <Pagination.Next onClick={handleNextPage(paginationProps)} disabled={paginationProps.page >= data.length / paginationProps.sizePerPage} > <i className="ri-arrow-right-s-line" /> </Pagination.Next>
                                                        </Pagination>
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    }
                                </PaginationProvider>


                            </>
                        )}
                    </ToolkitProvider>
                </div>
            </SimpleBar>
        </div>
    )
}

export default EmployeeTableBody
