function timeFormatter(dateString, formatString = 'hh:mm:ss', separator = ':', ampm = true) {
    const date = new Date(dateString);

    if (isNaN(date)) {
        return 'Invalid date';
    }

    let hours = date.getHours()
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    let amorpm = 'am'
    if (ampm)
        if (hours >= 12) {
            amorpm = 'pm'
            hours /= 12
        }

    const dateObj = {
        "h": hours.toString().padStart(1, '0'),
        "hh": hours.toString().padStart(2, '0'),
        "m": minutes.toString().padStart(1, '0'),
        "mm": minutes.toString().padStart(2, '0'),
        "s": seconds.toString().padStart(1, '0'),
        "ss": seconds.toString().padStart(2, '0'),
    }
    let timeFormat = formatString.split(separator)
    let formattedTime = []
    timeFormat.forEach((val) => {
        formattedTime.push(dateObj[val])
    })

    return formattedTime.join(separator)
}

export default timeFormatter;
