import React from 'react'
import { Toast } from 'react-bootstrap'

const ToastMsg = ({show,text}) => {
  return (
    <Toast className='bg-secondary text-white text-center' show={show} >
                {/* <Toast.Header className='bg-secondary text-white'>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Bootstrap</strong>
                </Toast.Header> */}
                <Toast.Body >{text}</Toast.Body>
            </Toast>
  )
}

export default ToastMsg
