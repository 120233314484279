import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import AlertMsg from '../../utils/Alert';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

const RenameStage = ({ show, hide,items,setItems, callBack,renameStageIndex }) => {
    const {
        register,
        formState: { errors },
        getValues,
        setValue,
        handleSubmit,
    } = useForm({ criteriaMode: "all", mode: "all" });
    useEffect(() => {
        setValue('stagename', items[renameStageIndex]?.stageType)
    }, [renameStageIndex])
    const renameStage = ()=>{
        let updatedStage = [...items]
        updatedStage[renameStageIndex].stageType = getValues('stagename')
        setItems(updatedStage)
        callBack()
    }
    return (
        <Modal show={show} onHide={hide} size="lg" centered >
            <Modal.Body>
                <Button bsPrefix="btn-close" type='button' onClick={hide}>
                    <span aria-hidden="true">×</span>
                </Button>
                <h5 className="mb-5">Rename Stage</h5>
                <Form onSubmit={handleSubmit(renameStage)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSubmit(renameStage);
                        }
                    }}>
                    <Form.Group className="mb-3">
                        <Form.Label>Stage Name</Form.Label>
                        <Form.Control
                            type="text" placeholder="Stage Name" name="stagename"
                            {...register("stagename", {
                                required: "Stage Name is required"
                            })} />
                        {errors.stagename && <p className='text-danger mt-1'>Stage Name is required</p>}
                    </Form.Group>
                    <Modal.Footer className="align-items-center">
                        {/* {loading &&
                            <div className='d-flex justify-content-center mb-2'>
                                <Spinner animation="border" variant="primary" />
                            </div>
                        } */}
                        <Button variant="secondary" onClick={hide}>Close</Button>
                        <Button type='submit' variant="primary"  >Update</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal >
    )
}

export default RenameStage
