import { GET_STAGES_FULFILLED, GET_STAGES_PENDING, GET_STAGES_REJECTED } from "../constants/Stages";
const initialState = {
    stages : []
}
export function StagesReducer(state = initialState,action){
    switch (action.type) {
        case GET_STAGES_FULFILLED:
            return { ...state, stages: action.payload, pending: false }
        case GET_STAGES_REJECTED:
            return { ...state, error: action.error, pending: false }
        case GET_STAGES_PENDING:
            return { ...state, pending: true }
        default:
            return state
    }
}