import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import { Archive, ChevronDown, ChevronUp, Edit, ExternalLink, Grid, List, MoreVertical, RefreshCw, Server, Settings, Slash, Star, Trash, Trash2, User } from 'react-feather';
import { connect } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { toggleTopNav } from '../../../redux/action/Theme';
import HkTooltip from '../../../components/@hk-tooltip/HkTooltip';
import { useState } from 'react';
import CreateNewEmployee from './CreateNewEmployee';
import { useSelector } from 'react-redux';

const EmployeeListHeader = ({ reload, setReload, topNavCollapsed, toggleTopNav }) => {

    const [addNewEmployee, setAddNewEmployee] = useState(false);
    const userDetails = useSelector(state => state.userReducer.data)
    return (
        <header className="contact-header">
            <div className="d-flex align-items-center">
                <h2 className='h-'>Employees</h2>
                {userDetails.roleType === 1 && <>
                    <Button variant="primary" className="btn-rounded btn-block m-4" onClick={() => setAddNewEmployee(!addNewEmployee)}>
                        Add new employee
                    </Button>
                    <CreateNewEmployee reload={reload} setReload={setReload} show={addNewEmployee} close={() => setAddNewEmployee(false)} />
                </>}
            </div>

            {/* <div className={classNames("hk-sidebar-togglable", { "active": show })} onClick={toggleSidebar} /> */}
        </header>
    )
}

const mapStateToProps = ({ theme }) => {
    const { topNavCollapsed } = theme;
    return { topNavCollapsed }
};

export default connect(mapStateToProps, { toggleTopNav })(EmployeeListHeader);