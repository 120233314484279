import React, { useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { Eye, EyeOff } from 'react-feather';
import { Link,useParams,useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";

//Image
// import logoutImg from '../../../../assets/dist/img/macaroni-logged-out.png';
import RegexUtils from '../../../utils/RegexUtils';
import axios from 'axios'
import AlertMsg from '../../../utils/Alert';
import { api } from '../../../api';
const Body = (props) => {
    // const [cpassword, setCpassword] = useState("");
    // const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const {token} = useParams()
    const history = useHistory();
    const [showAlert, setAlertShow] = useState(false)
    const [loading, setLoading] = useState(false)

    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
    const handleResetPassword = async (e) => {
        // alert('hi')
        // e.preventDefault();
        // props.history.push("/");
        console.log(token)
        try {
            setLoading(true)
            const {data} = await api.post('/reset-password/' + token,{
                password : getValues('password')
            })
            console.log(data)
            history.push('/auth/signin');

        } catch (error) {
            console.log(error)
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)
        }
        setLoading(false)
    }
    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit,
    } = useForm({ criteriaMode: "all", mode: "all" });
    return (
        <div className="hk-pg-body">
            <AlertMsg data={alertData} show={showAlert} setShow={setAlertShow} />

            <Container>
                <Row>

                    <Col xl={5} lg={6} md={7} sm={10} className="position-relative mx-auto">
                        <div className="auth-content py-md-0 py-8">
                            <Form className="w-100" onSubmit={handleSubmit(handleResetPassword)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        // e.preventDefault();
                                        handleSubmit(handleResetPassword)
                                    }
                                }}>
                                <Row>
                                    <Col lg={10} className="mx-auto">
                                        <h4 className="mb-4">Reset Password</h4>
                                        <Row className="gx-3">
                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                <div className="form-label-group">
                                                    <Form.Label>New Password</Form.Label>
                                                </div>
                                                <InputGroup className="password-check">
                                                    <span className="input-affix-wrapper">
                                                        <Form.Control
                                                            {...register("password", {
                                                                required: "Password is required",
                                                                pattern: {
                                                                    value: RegexUtils.PASSWORD,
                                                                    message: "Password is required",
                                                                },
                                                            })}
                                                            placeholder="Enter new password" 
                                                            // value={password} onChange={e => setPassword(e.target.value)} 
                                                            type={showPassword ? "text" : "password"} />

                                                        <Link to="#" className="input-suffix text-muted" onClick={() => setShowPassword(!showPassword)} >
                                                            <span className="feather-icon">
                                                                {
                                                                    showPassword
                                                                        ?
                                                                        <EyeOff className="form-icon" />
                                                                        :
                                                                        <Eye className="form-icon" />
                                                                }

                                                            </span>
                                                        </Link>
                                                    </span>
                                                </InputGroup>
                                                {errors.password && <p className='text-danger mt-1'>Password must be at least 8 characters long and include a mix of uppercase, lowercase, numbers, and special characters.</p>}

                                            </Col>
                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                <div className="form-label-group">
                                                    <Form.Label>Confirm Password</Form.Label>
                                                </div>
                                                <InputGroup className="password-check">
                                                    <span className="input-affix-wrapper">
                                                        <Form.Control
                                                            {...register("cpassword", {
                                                                required: "Password is required",
                                                                validate:(value)=>{
                                                                    return getValues('password') === value
                                                                },
                                                                pattern: {
                                                                    value: RegexUtils.PASSWORD,
                                                                    message: "Password is required",
                                                                },
                                                            })}
                                                            placeholder="Confirm your password" 
                                                            // value={cpassword} onChange={e => setCpassword(e.target.value)} 
                                                            type={showCPassword ? "text" : "password"} />

                                                        <Link to="#" className="input-suffix text-muted" onClick={() => setShowCPassword(!showCPassword)} >
                                                            <span className="feather-icon">
                                                                {
                                                                    showCPassword
                                                                        ?
                                                                        <EyeOff className="form-icon" />
                                                                        :
                                                                        <Eye className="form-icon" />
                                                                }

                                                            </span>
                                                        </Link>

                                                    </span>
                                                    
                                                </InputGroup>
                                                {errors.cpassword && <p className='text-danger mt-1'>The passwords do not match</p>}

                                            </Col>
                                        </Row>
                                        <div className="d-flex justify-content-center">
                                            {/* <Form.Check id="logged_in" className="form-check-sm mb-3" >
                                                <Form.Check.Input type="checkbox" defaultChecked />
                                                <Form.Check.Label className="text-muted fs-7">Keep me logged in</Form.Check.Label>
                                            </Form.Check> */}
                                        </div>
                                        {loading &&
                                            <div className='d-flex justify-content-center mb-2'>
                                                <Spinner animation="border" variant="primary" />
                                            </div>
                                        }
                                        <Button variant="primary" type="submit"  className="btn-uppercase btn-block">Reset</Button>
                                        <p className="p-xs mt-2 text-center"> <Link to="/auth/signin"><u>Signin</u></Link></p>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Body;
