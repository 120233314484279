import { DEFAULT_LAYOUT_STYLE } from "./constants/ThemeConstants";


export const THEME_CONFIG = {
	navCollapsed: true,
	dataHover: true,
	topNavCollapsed: false,
	layoutStyle: DEFAULT_LAYOUT_STYLE,
	// sideNavTheme: SIDE_NAV_LIGHT,
	// locale: 'en',
	// navType: NAV_TYPE_SIDE,
	// topNavColor: '#3e82f7',
	// headerNavColor: '',
	// mobileNav: false,
	// currentTheme: 'light',
	// direction: DIR_LTR
};