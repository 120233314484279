import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ContactAppSidebar from '../LeadAppSidebar';
import EditLeadBody from './EditLeadBody';
import EditLeadHeader from './EditLeadHeader';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios'
import { api } from '../../../api';
import AlertMsg from '../../../utils/Alert';
const EditLead = () => {
  const [data, setData] = useState({})
    const [reload, setReload] = useState(false)
    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!location.state || !location.state.data) return history.replace('/');
    const fetchData = async () => {
      try {
          let { data } = await api('/leads/' + location.state.data.id)
          console.log(data.data)
          setData(data.data)
      } catch (error) {
          console.log(error)
          console.log(error)
          let details
          error?.response?.data?.message ? details = {
              variant: 'danger',
              title: 'Error',
              msg: error.response.data.message
          } : details = {
              variant: 'danger',
              title: 'Error',
              msg: "Something went wrong try later"
          }
          setAlertData(details)
          setAlertShow(true)
      }
  }
  fetchData()
  
  }, [location.state,reload]);

  return (
    <div className="hk-pg-body py-0">
      {/* <ContactAppSidebar /> */}
      <div className="contactapp-detail-wrap">
      <AlertMsg data={alertData} show={showAlert} setShow={setAlertShow} />
        <EditLeadHeader  leadName={location.state?.data.fullName }/>
        {/* Check if location.state.data is defined before passing it to EditLeadBody */}
        {location.state?.data && data.id && <EditLeadBody reload={reload} setReload={setReload} data={data} />}
      </div>
    </div>
  );
};

export default EditLead;
