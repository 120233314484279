
import { combineReducers } from 'redux';
import ChatReducer from './Chat';
import ChatPopupReducer from './ChatPopup';
import EmailReducer from './Email';
import Theme from './Theme';
import ToDoReducer from './ToDo';
import { UserReducer } from './UserDetails';
import { TaskReducer } from './Task';
import { StagesReducer } from './Stages';

const reducers = combineReducers({
    stagesReducer : StagesReducer,
    taskReducer : TaskReducer,
    userReducer : UserReducer,
    theme: Theme,
    chatReducer: ChatReducer,
    emailReducer: EmailReducer,
    chatPopupReducer: ChatPopupReducer,
    toDoReducer: ToDoReducer,
});

export default reducers;