import React from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import HkDropZone from '../../components/@hk-drop-zone/HkDropZone';
import HkTags from '../../components/@hk-tags/@hk-tags';
import { addRow, data } from './LeadList/TableData';
import { useState } from 'react';
import avatar1 from '../../assets/dist/img/avatar1.jpg';
import { useForm } from 'react-hook-form';
import Select from 'react-select';

import AlertMsg from '../../utils/Alert';
import RegexUtils from '../../utils/RegexUtils';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { api } from '../../api';
const CreateNewLead = ({ show, close, reload, setReload }) => {
    const [loading, setLoading] = useState(false)
    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
    const stages = useSelector(state => state.stagesReducer.stages)
    const userDetails = useSelector(state => state.userReducer.data)

    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit,
    } = useForm({ criteriaMode: "all", mode: "all" });
    const [assignTo, setAssignTo] = useState({
        value: userDetails.fullName,
        label: userDetails.fullName,
        id: userDetails.id
    });
    const [employeesOptions, setEmployeesOptions] = useState([])
    const [assignToError, setAssignToError] = useState(false)
    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const { data } = await api.get('/admin/users')
                let employees = data.data.map((employee) => {
                    return {
                        value: employee.fullName,
                        label: employee.fullName,
                        id: employee.id
                    }
                })
                console.log(employees)
                setEmployeesOptions(employees)
            } catch (error) {
                console.log(error)
                let details
                error?.response?.data?.message ? details = {
                    variant: 'danger',
                    title: 'Error',
                    msg: error.response.data.message
                } : details = {
                    variant: 'danger',
                    title: 'Error',
                    msg: "Something went wrong try later"
                }
                setAlertData(details)
                setAlertShow(true)
            }
        }
        fetchEmployees()
    }, [])
    const createLead = async () => {
        try {
            console.log(assignTo?.id)
            if (!assignTo?.id) return setAssignToError(true)
            setLoading(true)
            const { data: response } = await api.post('/leads/add', {
                fullName: getValues('name'),
                companyName: getValues('companyname'),
                email: getValues('email'),
                phone: getValues('phone'),
                stageId: getValues('stage'),
                address: getValues('address'),
                selectedProcessor: assignTo.id
            }
            )
            console.log(response)
            setReload(!reload)
            close()
        } catch (error) {
            console.log(error)
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)
        }
        setLoading(false)

    }
    return (
        <Modal show={show} onHide={close} centered size="lg" className="add-new-contact" >
            <Modal.Body>
                <Button bsPrefix="btn-close" type='button' onClick={close}>
                    <span aria-hidden="true">×</span>
                </Button>
                <h5 className="mb-5">Create New Lead</h5>
                <AlertMsg className='w-8' data={alertData} show={showAlert} setShow={setAlertShow} />

                <Form onSubmit={handleSubmit(createLead)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSubmit(createLead);
                        }
                    }}>

                    <div className="title title-xs title-wth-divider text-primary text-uppercase my-4">
                        <span>Info</span>
                    </div>
                    <Row className="gx-2">
                        <div className="col-sm-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                    {...register("name", {
                                        required: "Name is required",
                                        pattern: {
                                            value: RegexUtils.NAME,
                                            message: "Valid Full name is required",
                                        },
                                    })}
                                    // value={fullName} onChange={e => setFullName(e.target.value)} 
                                    type="text" />
                                {errors.name && <p className='text-danger mt-1'>Valid Full Name is required</p>}

                            </Form.Group>
                        </div>
                        <div className="col-sm-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Email ID</Form.Label>
                                <Form.Control
                                    {...register("email", {
                                        required: "Email is invalid",
                                        pattern: {
                                            value: RegexUtils.EMAIL,
                                            message: "Email is invalid",
                                        },
                                    })}
                                    // value={email} onChange={e => setEmail(e.target.value)} 
                                    type="email" />
                                {errors.email && <p className='text-danger mt-1'>Email is invalid</p>}

                            </Form.Group>
                        </div>

                    </Row>
                    <Row className="gx-3">

                        <div className="col-sm-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    {...register("phone", {
                                        required: "Phone ID is required",
                                        pattern: {
                                            value: RegexUtils.PHONE,
                                            message: "Phone number is invalid",
                                        },
                                    })}
                                    // value={phone} onChange={e => setPhone(e.target.value)} 
                                    type="text" />
                                {errors.phone && <p className='text-danger mt-1'>Phone number is invalid</p>}

                            </Form.Group>
                        </div>
                        <div className="col-sm-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Stage</Form.Label>

                                <Form.Select
                                    {...register("stage")}
                                // onChange={e => setStage(e.target.value)} value={stage}
                                >
                                    {stages.map((stage) => <option value={stage.id}>{stage.stageType}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>

                        </div>
                    </Row>

                    <div className="title title-xs title-wth-divider text-primary text-uppercase my-4"><span>Company Info</span></div>
                    <Row className="gx-3">
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                    {...register("companyname", {
                                        required: "Company Name is required"
                                    })}
                                    // value={company} onChange={e => setCompany(e.target.value)} 
                                    type="text" />
                                {errors.companyname && <p className='text-danger mt-1'>Company Name is required</p>}

                            </Form.Group>
                        </Col>

                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    {...register("address", {
                                        required: "Address is required"
                                    })}
                                    type="text" />
                                {errors.address && <p className='text-danger mt-1'>Address is required</p>}

                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Assign To</Form.Label>
                                <Select
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderColor: '#032390',
                                            boxShadow: state.isFocused ? null : null,
                                        }),
                                        menuList: (provided, state) => ({
                                            ...provided,
                                            height: '80px',
                                        }),

                                    }}
                                    defaultValue={{
                                        value: userDetails.fullName,
                                        label: userDetails.fullName,
                                        id: userDetails.id
                                    }}
                                    value={assignTo}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="assignTo"
                                    options={employeesOptions}
                                    onChange={(selectedOption) => {
                                        setAssignTo(selectedOption);
                                        setAssignToError(false)
                                    }}
                                />
                                {assignToError && <p className='text-danger mt-1'>Person Name is required</p>}
                            </Form.Group>

                        </Col>


                    </Row>


                    <Modal.Footer className="align-items-center">
                        {loading &&
                            <div className='d-flex justify-content-center mb-2'>
                                <Spinner animation="border" variant="primary" />
                            </div>
                        }
                        <Button variant="secondary" type='button' onClick={close}>Close</Button>
                        <Button type='submit' variant="primary">Create Lead</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>

        </Modal>
    )
}

export default CreateNewLead;
