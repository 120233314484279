import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { Col, Container, Form, Pagination, Row } from 'react-bootstrap';
import { DATASET } from './Data';
import DragCard from './DragCard';
import Task from './Task';

const Body = ({ tasks }) => {



    return (
        <>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <Form.Select className="mw-150p">
                    <option value={0}>All Tasks</option>
                    <option value={1}>Completed</option>
                    <option value={2}>Up Coming</option>
                    <option value={3}>Expired</option>
                </Form.Select>
                <Form.Select className="mw-150p ">
                    <option value={0}>Sort by date</option>
                    <option value={1}>Sort By Time</option>
                    <option value={2}>Sort By Category</option>
                    <option value={3}>Sort By Priority</option>
                    <option value={4}>Sort By Title</option>
                    <option value={5}>Sort By Assignee</option>
                </Form.Select>
            </div>
            {console.log(tasks)}
            {tasks.map((task) => (
                <div>
                    <Task task={{...task}} />
                </div>
            ))}
        </>
    )
}

export default Body
