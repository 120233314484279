import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import AlertMsg from '../../utils/Alert';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { api } from '../../api';
import { useState } from 'react';
import { getStages } from '../../redux/action/Stages';
import { useDispatch } from 'react-redux';

const AddStage = ({ show, hide }) => {
    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit,
    } = useForm({ criteriaMode: "all", mode: "all" });
    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
    const dispatch = useDispatch()

    const addStage = async () => {
        try {
            const { data } = await api.post(`/leads/stages/add`, {
                stageType: getValues('stagename'),
                description: ""
            })
            console.log(data)
            let details = {
                variant: 'success',
                title: 'Success',
                msg: "Stage Updated"
            }
            setAlertData(details)
            setAlertShow(true)
            dispatch(getStages)
        } catch (error) {
            console.log(error)
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)

        }
    }

    return (
        <Modal show={show} onHide={hide} size="lg" centered >
            <Modal.Body>
                <Button bsPrefix="btn-close" type='button' onClick={hide}>
                    <span aria-hidden="true">×</span>
                </Button>
                <h5 className="mb-5">Add New Stage</h5>
                <AlertMsg className='w-100' data={alertData} show={showAlert} setShow={setAlertShow} />

                <Form onSubmit={handleSubmit(addStage)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSubmit(addStage);
                        }
                    }}>
                    <Form.Group className="mb-3">
                        <Form.Label>Stage Name</Form.Label>
                        <Form.Control
                            type="text" placeholder="Stage Name" name="stagename"
                            {...register("stagename", {
                                required: "Stage Name is required"
                            })} />
                        {errors.stagename && <p className='text-danger mt-1'>Stage Name is required</p>}
                    </Form.Group>
                    <Modal.Footer className="align-items-center">
                        {/* {loading &&
                            <div className='d-flex justify-content-center mb-2'>
                                <Spinner animation="border" variant="primary" />
                            </div>
                        } */}
                        <Button variant="secondary" onClick={hide}>Close</Button>
                        <Button type='submit' variant="primary"  >Add</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal >
    )
}

export default AddStage
