import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const Confirm = ({show,onHide,title,desc,callBack,cancelText,okText,okTextVariant}) => {
  return (
    <Modal.Dialog className='m-2'>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{desc}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        {cancelText}
                    </Button>
                    <Button variant={okTextVariant} onClick={callBack}>
                        {okText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Modal.Dialog>
  )
}

export default Confirm
