import React, { useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import { Button, Col, Form, Pagination, Row } from 'react-bootstrap';
import { Star } from 'react-feather';
//Bootstrap Table Library
import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import paginationFactory, { PaginationProvider, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
//Table data 
import { columns } from './TableData';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import AlertMsg from '../../../utils/Alert';
import { useSelector } from 'react-redux';
import { api } from '../../../api';
import { useRef } from 'react';
//Table search
const { SearchBar } = Search;

const LeadTableBody = ({ reload, setReload }) => {
    const history = useHistory()
    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];


    //Custom Total layout
    const customTotal = (from, to, size) => (
        <span className="ms-2">
            {from} - {to} of {size}
        </span>
    );

    //Custom Select Box
    const selectRow = {
        mode: 'checkbox',
        selectionHeaderRenderer: ({ mode, indeterminate, ...rest }) => (
            <Form.Check
                type={mode}
                className="fs-6 mb-0"
                ref={(input) => {
                    if (input) input.indeterminate = false;
                }}
                {...rest}
                readOnly
            />
        ),
        selectionRenderer: ({ mode, checked }) => (
            <div className="d-flex align-items-center">
                <Form.Check type={mode} checked={checked} readOnly />

            </div>
        ),
        style: { backgroundColor: '#ebf5f5', color: "black" }

    };

    //Options for Table Paginations
    const handleNextPage = ({
        page,
        onPageChange
    }) => () => {
        onPageChange(page + 1);
    }

    const handlePrevPage = ({
        page,
        onPageChange
    }) => () => {
        onPageChange(page - 1);
    }
    const options = {
        custom: true,
        paginationSize: 4,
        pageStartIndex: 1,
        withFirstAndLast: false,
        sizePerPage: 10,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        totalSize: columns.length
    };
    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
       

const [data, setData] = useState([])
const userDetails = useSelector(state => state.userReducer)
const [leadsType,setLeadsType] = useState('active')
useEffect(() => {
    const fetchData = async () => {
        try {
            // let { data } = await api.get('/leads/all/' + leadTypes.current)
            let { data } = await api.get('/leads/all?stage=' + leadsType)
            console.log(data.data)
            setData(data.data)
        } catch (error) {
            console.log(error)
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)
        }
    }
    fetchData()
}, [reload,leadsType])


return (
    <div className="contact-body">
        <AlertMsg data={alertData} show={showAlert} setShow={setAlertShow} />
        <SimpleBar className="nicescroll-bar">
            <div className="collapse" id="collapseQuick">
                <div className="quick-access-form-wrap">
                    <Form className="quick-access-form border">
                        <Row className="gx-3">
                            <Col xxl={10}>
                                <div className="position-relative">
                                    <div className="dropify-square">
                                        <input type="file" className="dropify-1" />
                                    </div>
                                    <Col md={12}>
                                        <Row className="gx-3">
                                            <Col lg={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control placeholder="First name*" type="text" />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Control placeholder="Last name*" type="text" />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control placeholder="Email Id*" type="text" />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Control placeholder="Phone" type="text" />
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                    </Col>
                                </div>
                            </Col>

                        </Row>
                    </Form>
                </div>
            </div>
            <div className="contact-list-view">
                <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={data}
                    search
                >
                    {props => (
                        <>

                            <PaginationProvider
                                pagination={paginationFactory(options)}
                            >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <>
                                            <Row className="mb-3" >
                                                <Col xs={7} mb={3}>
                                                    <div className="contact-toolbar-left">


                                                        <Button size="sm" className="d-flex align-items-center">
                                                            Export to CSV
                                                        </Button>
                                                        <Form.Group className="d-xxl-flex ms-5  align-items-center mb-0">
                                                            <Form.Select onChange={(e) => setLeadsType(e.target.value)} size='sm' className="w-130p">
                                                                <option value={'active'}>Active</option>
                                                                <option value={'won'}>Won</option>
                                                                <option value={'lost'}>Lost</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col xs={5} mb={3}>
                                                    <div className="contact-toolbar-right">
                                                        <div className="dataTables_filter">
                                                            <Form.Label>
                                                                <Form.Control as={SearchBar} {...props.searchProps} size="sm" type="search" placeholder="Search" />
                                                            </Form.Label>
                                                        </div>
                                                        {/* <div className="dataTables_paginate paging_simple_numbers" id="datable_1_paginate">
                                                                <ul className="pagination custom-pagination pagination-simple m-0">
                                                                    <li className="paginate_button page-item previous disabled" id="datable_1_previous">
                                                                        <a href="#some" data-dt-idx={0} tabIndex={0} className="page-link">
                                                                            <i className="ri-arrow-left-s-line" />
                                                                        </a>
                                                                    </li>
                                                                    <li className="paginate_button page-item active">
                                                                        <a href="#some" data-dt-idx={1} tabIndex={0} className="page-link">1</a>
                                                                    </li>
                                                                    <li className="paginate_button page-item ">
                                                                        <a href="#some" data-dt-idx={2} tabIndex={0} className="page-link">2</a>
                                                                    </li>
                                                                    <li className="paginate_button page-item next" id="datable_1_next">
                                                                        <a href="#some" data-dt-idx={3} tabIndex={0} className="page-link">
                                                                            <i className="ri-arrow-right-s-line" />
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <BootstrapTable
                                                keyField='id'
                                                data={data}
                                                columns={columns}
                                                selectRow={selectRow}
                                                bordered={false}
                                                wrapperClasses="table-responsive"
                                                classes="nowrap w-100 mb-5"
                                                {...paginationTableProps}
                                                defaultSorted={defaultSorted}
                                                {...props.baseProps}
                                            />

                                            <Row>
                                                <Col>
                                                    <PaginationTotalStandalone
                                                        {...paginationProps}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Pagination className='custom-pagination pagination-simple d-flex justify-content-end'>
                                                        <Pagination.Prev onClick={handlePrevPage(paginationProps)} disabled={paginationProps.page === 1} > <i className="ri-arrow-left-s-line" /> </Pagination.Prev>
                                                        <Pagination.Item active>{paginationProps.page}</Pagination.Item>
                                                        <Pagination.Next onClick={handleNextPage(paginationProps)} disabled={paginationProps.page >= data.length / paginationProps.sizePerPage} > <i className="ri-arrow-right-s-line" /> </Pagination.Next>
                                                    </Pagination>
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                }
                            </PaginationProvider>


                        </>
                    )}
                </ToolkitProvider>
            </div>
        </SimpleBar>
    </div>
)
}

export default LeadTableBody
