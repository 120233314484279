import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import { Archive, ChevronDown, ChevronUp, Edit, ExternalLink, Grid, List, MoreVertical, RefreshCw, Server, Settings, Slash, Star, Trash, Trash2, User } from 'react-feather';
import { connect } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { toggleTopNav } from '../../redux/action/Theme';
import HkTooltip from '../../components/@hk-tooltip/HkTooltip';
import { useState } from 'react';
import CreateNewLead from './CreateNewLead';

const LeadTableHeader = ({ reload, setReload, topNavCollapsed, toggleTopNav }) => {

    const leadListRoute = useRouteMatch("/app/leads/leads-list");
    const [addNewLead, setAddNewLead] = useState(false);

    return (
        <header className="contact-header">
            <div className="d-flex align-items-center">
                <h2 className='h-'>Leads</h2>
                <Button variant="primary" className="btn-rounded btn-block m-4" onClick={() => setAddNewLead(!addNewLead)}>
                    Add new lead
                </Button>
                <CreateNewLead reload={reload} setReload={setReload} show={addNewLead} close={() => setAddNewLead(false)} />

            </div>
            <div className="contact-options-wrap">
                <Dropdown className="inline-block" >
                    <Dropdown.Toggle as="a" href="#" className="btn btn-icon btn-flush-dark flush-soft-hover no-caret active">
                        <span className="icon">
                            <span className="feather-icon">
                                {leadListRoute ? <List /> : <Grid />}
                            </span>
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                        <Dropdown.Item as={NavLink} to="leads-list" activeClassName="active" ><span className="feather-icon dropdown-icon">
                            <List />
                        </span>
                            <span>List View</span>
                        </Dropdown.Item>
                        <Dropdown.Item as={NavLink} to="/app/taskboard/kanban-board" activeClassName="active">
                            <span className="feather-icon dropdown-icon">
                                <Grid />
                            </span>
                            <span>Grid View</span>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>


                {/* <Button as="a" variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover no-caret d-sm-inline-block d-none" href="#">
                    <HkTooltip title="Refresh" placement="top" >
                        <span className="icon">
                            <span className="feather-icon">
                                <RefreshCw />
                            </span>
                        </span>
                    </HkTooltip>
                </Button> */}

                {/* <Dropdown className="inline-block ms-1">
                    <Dropdown.Toggle as="a" href="#" className="btn btn-flush-dark btn-icon btn-rounded btn-flush-dark flush-soft-hover no-caret d-lg-inline-block d-none">
                        <HkTooltip placement="top" title="More">
                            <span className="icon">
                                <span className="feather-icon">
                                    <MoreVertical />
                                </span>
                            </span>
                        </HkTooltip>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                        <Dropdown.Item>
                            <span className="feather-icon dropdown-icon">
                                <Trash />
                            </span>
                            <span>Delete Leads</span>
                        </Dropdown.Item>

                        <Dropdown.Item>
                            <span className="feather-icon dropdown-icon">
                                <Archive />
                            </span>
                            <span>Archive Contacts</span>
                        </Dropdown.Item>
                        <div className="dropdown-divider" />
                        <Dropdown.Item>
                            <span className="feather-icon dropdown-icon">
                                <Slash />
                            </span>
                            <span>Block Content</span>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <span className="feather-icon dropdown-icon">
                                <ExternalLink />
                            </span>
                            <span>Feedback</span>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown> */}
                <div className="v-separator d-lg-block d-none" />
                <Button as="a" href="#" className="btn-icon btn-flush-dark btn-rounded flush-soft-hover hk-navbar-togglable d-sm-inline-block d-none" onClick={() => toggleTopNav(!topNavCollapsed)} >
                    <HkTooltip placement={topNavCollapsed ? "bottom" : "top"} title="Collapse" >
                        <span className="icon">
                            <span className="feather-icon">
                                {
                                    topNavCollapsed ? <ChevronDown /> : <ChevronUp />
                                }
                            </span>
                        </span>
                    </HkTooltip>
                </Button>
            </div>
            {/* <div className={classNames("hk-sidebar-togglable", { "active": show })} onClick={toggleSidebar} /> */}
        </header>
    )
}

const mapStateToProps = ({ theme }) => {
    const { topNavCollapsed } = theme;
    return { topNavCollapsed }
};

export default connect(mapStateToProps, { toggleTopNav })(LeadTableHeader);