import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css"
import { useDispatch } from 'react-redux';
import { getTasks } from '../../../../redux/action/Task';
import { LocalTimeConverter } from '../../../../utils/LocalTimeConverter';
import { useSelector } from 'react-redux';
import { Toast } from 'react-bootstrap';
import ToastMsg from '../../../../utils/Toast';
const Body = ({ pastDateValidator, userId, setIsUpdate, tasks, setAddNewTask, setTaskData }) => {
    const localizer = momentLocalizer(moment)
    const defaultDate = useMemo(() => new Date(), [])
    // const [events, setEvents] = useState([])
    const userDetails = useSelector(state => state.userReducer)
    const [showToast, setShowToast] = useState(false);
    const [toastText, setToastText] = useState('')
    const events = useMemo(() =>
        tasks.map((task) => {
            return {
                allDay: false,
                start: new Date(task.startDate),
                end: new Date(task.endDate),
                id: task.id,
                description: task.description,
                title: task.title,
                isCompleted: task.isCompleted,
                assignTo: { value: task.user.fullName, label: task.user.fullName, id: task.assignedTo },
                priority: task.priorityId
            }
        }, [tasks])
    )
    const [view, setView] = useState(Views.WEEK)
    const onView = useCallback((newView) => setView(newView), [setView])
    // const dispatch = useDispatch()
    // useEffect(()=>{
    //     dispatch(getTasks(userId))
    // },[])
    console.log(events)
    return (
        <div className='my-5 h-100'>
            <div className='position-absolute z-index-3 '>
                <ToastMsg show={showToast} text={toastText} />
            </div>
            <Calendar
                step={15}
                defaultDate={defaultDate}
                events={events}
                localizer={localizer}
                // onSelecting={(range)=>alert(JSON.stringify(range))}
                onSelectSlot={(range) => {

                    if (view === Views.DAY || view === Views.WEEK) {
                        if (!pastDateValidator(range.start)) {
                            setToastText('past date not allowed')
                            setShowToast(true)
                            return setTimeout(() => {
                                setShowToast(false)
                            }, 3000);
                        }
                        setTaskData({
                            id: null,
                            title: '',
                            description: '',
                            priority: 3,
                            startDate: LocalTimeConverter(range.start),
                            endDate: LocalTimeConverter(range.end),
                            isCompleted: false,
                            assignTo: { value: userDetails.data.fullName, label: userDetails.data.fullName, id: userDetails.data.id }
                        })
                        setIsUpdate(false)
                        setAddNewTask(true)
                    }
                }}
                selectable
                startAccessor="start"
                endAccessor="end"
                style={{ height: 600, width: "100%" }}
                onSelectEvent={(event) => {
                    console.log(event.isCompleted)
                    setTaskData({
                        id: event.id,
                        title: event.title,
                        description: event.description,
                        priority: event.priority,
                        startDate: LocalTimeConverter(event.start),
                        endDate: LocalTimeConverter(event.end),
                        isCompleted: event.isCompleted,
                        assignTo: event.assignTo
                    })
                    setIsUpdate(true)
                    setAddNewTask(true)
                }}
                onView={onView}
                defaultView={Views.WEEK}
            />
        </div>
    )
}

export default Body
