import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk"; // Import redux-thunk
import reducers from "../reducer";

const configureStore = (preloadedState) => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    // Apply the redux-thunk middleware using applyMiddleware
    const store = createStore(
        reducers,
        preloadedState,
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
}

export const store = configureStore();


// import { configureStore } from "@reduxjs/toolkit";
// import userDetailsSlice from "../slices/userDetails";
// export const store = configureStore({
//     reducer : {
//         userDetails : userDetailsSlice
//     }
// })