import React from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import axios from 'axios'
import AlertMsg from '../../../utils/Alert';
import { useSelector } from 'react-redux';
import { api } from '../../../api';
const UpdateStage = ({stageId, leadId, show, close, reload, setReload }) => {


    // const [stage, setStage] = useState('In Pipeline')
    const [loading, setLoading] = useState(false)

    const [selectedStage, setSelectedStage] = useState(stageId);

    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit,
    } = useForm({ criteriaMode: "all", mode: "all" });
    const stages = useSelector(state => state.stagesReducer.stages)

    const updateStage = async () => {
        try {
            const { data } = await api.patch(`/leads/${leadId}/stage`,{
                stageId: selectedStage
            })
            console.log(data)
            let details = {
                variant: 'success',
                title: 'Success',
                msg: "Stage Updated"
            }
            setAlertData(details)
            setAlertShow(true)
            setReload(!reload)
        } catch (error) {
            console.log(error)
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)

        }

    }
    return (
        <Modal show={show} onHide={close} centered size="lg" className="add-new-contact" >
            <Modal.Body>
                <Button bsPrefix="btn-close" type='button' onClick={close}>
                    <span aria-hidden="true">×</span>
                </Button>
                <h5 className="mb-5">Update Stage</h5>
                <AlertMsg className='w-80' data={alertData} show={showAlert} setShow={setAlertShow} />

                <Form onSubmit={handleSubmit(updateStage)}>
                    <Form.Group className="mb-3">
                        {stages.map((stage) => {
                            // { console.log(data.companyLeadStage.order, stage.id) }
                            return (
                                <Form.Check
                                    type="radio"
                                    label={stage.stageType}
                                    value={stage.id}
                                    checked={Number(selectedStage) === Number(stage.id)}
                                    onChange={e => setSelectedStage(e.target.value)}
                                />
                            )
                        })}

                        

                    </Form.Group>

                    <Modal.Footer className="align-items-center">
                        {loading &&
                            <div className='d-flex justify-content-center mb-2'>
                                <Spinner animation="border" variant="primary" />
                            </div>
                        }
                        <Button variant="secondary" type='button' onClick={close}>Close</Button>
                        <Button type='submit' variant="primary">Update Stage</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>

        </Modal>
    )
}

export default UpdateStage;
