import React, { useState } from 'react';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

//Images
import slide3 from '../../../assets/dist/img/slide3.jpg';
import slide4 from '../../../assets/dist/img/slide4.jpg';
import { useForm } from 'react-hook-form';
import RegexUtils from '../../../utils/RegexUtils';
import axios from 'axios'
import AlertMsg from '../../../utils/Alert';
import { Eye, EyeOff } from 'react-feather';
import { api } from '../../../api';

const Body = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false)

    // const [fullName, setFullName] = useState('')
    // const [companyName, setCompanyName] = useState('')
    // const [email, setEmail] = useState('')
    // const [phone, setPhone] = useState('')
    // const [password, setPassword] = useState('')

    const history = useHistory();

    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit,
    } = useForm({ criteriaMode: "all", mode: "all" });
    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
    const [isChecked, setIsChecked] = useState(true);

    const handleRegister = async (e) => {
        // alert('hi')
        // e.preventDefault();
        // props.history.push("/");
        try {
            setLoading(true)

            const { data } = await api.post('/register', {
                fullName: getValues('name'),
                companyName: getValues('compnayname'),
                email: getValues('email'),
                phone: getValues('phone'),
                password: getValues('password')
            })
            console.log(data)
            history.push('/auth/signin')
        } catch (error) {
            console.log(error)
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)
        }
        setLoading(false)

    }
    return (
        <div className="hk-pg-body">
            <AlertMsg data={alertData} show={showAlert} setShow={setAlertShow} />

            <Container>
                <Row>
                    <Col xl={5} lg={6} md={7} sm={10} className="position-relative mx-auto">
                        <div className="auth-content py-md-0 py-8">
                            <Form className="w-100" onSubmit={
                                handleSubmit(handleRegister)
                            }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        // e.preventDefault();
                                        handleSubmit(handleRegister);
                                    }
                                }}>
                                <Row>
                                    <Col lg={10} className="mx-auto">
                                        <h4 className="text-center mb-4">Create your account</h4>
                                        {/* <Button variant="outline-dark" className="btn-rounded btn-block mb-3">
                                            <span>
                                                <span className="icon">
                                                    <FontAwesomeIcon icon={faGoogle} />
                                                </span>
                                                <span>Sign Up with Gmail</span>
                                            </span>
                                        </Button>
                                        <Button variant="social-facebook" className="btn-social btn-rounded btn-block">
                                            <span>
                                                <span className="icon">
                                                    <FontAwesomeIcon icon={faFacebook} />
                                                </span>
                                                <span>Sign Up with Facebook</span>
                                            </span>
                                        </Button>
                                        <div className="title-sm title-wth-divider divider-center my-4"><span>Or</span></div>
                                         */}
                                        <Row className="gx-3">
                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                <Form.Label>Full Name</Form.Label>
                                                <Form.Control
                                                    {...register("name", {
                                                        required: "Name is required",
                                                        pattern: {
                                                            value: RegexUtils.NAME,
                                                            message: "Valid Full name is required",
                                                        },
                                                    })}
                                                    // value={fullName}
                                                    // onChange={e => setFullName(e.target.value)}
                                                    placeholder="Enter your name" type="text" />
                                                {errors.name && <p className='text-danger mt-1'>Valid Full Name is required</p>}


                                            </Col>
                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                <Form.Label>Company Name</Form.Label>
                                                <Form.Control
                                                    {...register("compnayname", {
                                                        required: "company is required",
                                                    })}
                                                    // value={companyName}
                                                    // onChange={e => setCompanyName(e.target.value)}
                                                    placeholder="Enter company name" type="text" />
                                                {errors.compnayname && <p className='text-danger mt-1'>Company name is required</p>}

                                            </Col>
                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    {...register("email", {
                                                        required: "Email ID is required",
                                                        pattern: {
                                                            value: RegexUtils.EMAIL,
                                                            message: "Email is invalid",
                                                        },
                                                    })}
                                                    // value={email}
                                                    // onChange={e => setEmail(e.target.value)}
                                                    placeholder="Enter your email" type="text" />
                                                {errors.email && <p className='text-danger mt-1'>Email is invalid</p>}

                                            </Col>
                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control
                                                    {...register("phone", {
                                                        required: "Phone ID is required",
                                                        pattern: {
                                                            value: RegexUtils.PHONE,
                                                            message: "Phone number is invalid",
                                                        },
                                                    })}
                                                    // value={phone}
                                                    // onChange={e => setPhone(e.target.value)}
                                                    placeholder="Enter your phone number" type="text" />
                                                {errors.phone && <p className='text-danger mt-1'>Phone number is invalid</p>}

                                            </Col>
                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                <Form.Label>Password</Form.Label>
                                                <InputGroup className="password-check">
                                                    <span className="input-affix-wrapper affix-wth-text">
                                                        <Form.Control

                                                            {...register("password", {
                                                                required: "password  is required",
                                                                pattern: {
                                                                    value: RegexUtils.PASSWORD,
                                                                    message: "Invalid Password",
                                                                },
                                                            })}
                                                            // value={password}
                                                            // onChange={e => setPassword(e.target.value)}
                                                            placeholder="Enter password" type={showPassword ? "text" : "password"} />

                                                        <Link to="#" className="input-suffix text-muted" onClick={() => setShowPassword(!showPassword)} >
                                                            <span className="feather-icon">
                                                                {
                                                                    showPassword
                                                                        ?
                                                                        <EyeOff className="form-icon" />
                                                                        :
                                                                        <Eye className="form-icon" />
                                                                }

                                                            </span>
                                                        </Link>
                                                    </span>
                                                </InputGroup>
                                                {errors.password && <p className='text-danger mt-1'>Password must be at least 8 characters long and include a mix of uppercase, lowercase, numbers, and special characters.</p>}

                                            </Col>
                                        </Row>
                                        <Form.Check id="logged_in" className="form-check-sm mb-3" >
                                            <Form.Check.Input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                                            <Form.Check.Label className="text-muted fs-7">
                                                By creating an account you specify that you have read and agree with our <Link to="#">Tearms of use</Link> and <Link to="#">Privacy policy.</Link>
                                            </Form.Check.Label>
                                        </Form.Check>
                                        {loading &&
                                            <div className='d-flex justify-content-center mb-2'>
                                                <Spinner animation="border" variant="primary" />
                                            </div>
                                        }
                                        <Button variant='primary' disabled={!isChecked} className="btn-rounded btn-uppercase btn-block" type='submit'
                                        >Create account</Button>
                                        <p className="p-xs mt-2 text-center">Already a member ? <Link to="signin"><u>Sign In</u></Link></p>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default Body
