function dateFormatter(dateString, formatString = 'dd/MMMM/yyyy',separator = '/') {
    const date = new Date(dateString);

    if (isNaN(date)) {
        return 'Invalid date';
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = date.getMonth()
    const year = date.getFullYear()
   
    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const monthLongNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const dateObj = {
        "d" : day,
        "dd" : day,
        "MM" : (month + 1).toString().padStart(2, '0'),
        "MMM" : monthNames[month],
        "MMMM" : monthLongNames[month],
        "yy" : year.toString().substring(2,4),
        "yyyy" : year
    }
    let dateFormat = formatString.split(separator)
    let formattedDate = []
    dateFormat.forEach((val)=>{
        formattedDate.push(dateObj[val])
    })    
    
    return formattedDate.join(separator)
}

export default dateFormatter;
