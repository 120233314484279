import { api } from "../../api";
import { GET_STAGES_FULFILLED, GET_STAGES_PENDING, GET_STAGES_REJECTED } from "../constants/Stages";
export const getStages = async (dispatch) => {
    try {
        dispatch({ type: GET_STAGES_PENDING, pending: true })
        const { data } = await api.get('/leads/stages/all')
        console.log('stage action',data.data)
        dispatch({ type: GET_STAGES_FULFILLED, payload: data.data, pending: false })
    } catch (error) {
        console.log(error)
        dispatch({ type: GET_STAGES_REJECTED, error: error.message, pending: false })
    }
}