import * as Icons from 'tabler-icons-react';
import HkBadge from '../../components/@hk-badge/@hk-badge';

export const NavMenu = [
    {
        group: '',
        contents: [
            {
                name: 'Dashboard',
                // icon: <Icons.Template />,
                path: '/dashboard',
                // badge: <HkBadge size="sm" bg="pink" soft className="ms-xl-2 ms-auto" >hot</HkBadge>
            },
        ]
    },
    {
        group: 'app',
        contents: [
            {
                name: 'Leads',
                icon: <Icons.Inbox />,
                path: '/app/leads/leads-list',
                grp_name: "app",
            },
            {
                name: 'Employees',
                icon: <Icons.Inbox />,
                path: '/app/employees/employee-list',
                grp_name: "app",
            },
            // {
            //     id: 'dash_chatpop',
            //     name: 'Chat Popup',
            //     icon: <Icons.MessageCircle />,
            //     path: '/app/chat-bot',
            //     childrens: [
            //         {
            //             name: 'Direct Message',
            //             path: '/app/chat-bot/chatpopup',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Chatbot',
            //             path: '/app/chat-bot/chatbot',
            //             grp_name: "app",
            //         },
            //     ]
            // },
            // {
            //     id: 'dash_chatpop',
            //     name: 'Calendar',
            //     icon: <Icons.CalendarTime />,
            //     path: '/app/calendar',
            //     grp_name: "app",
            // },
            // {
            //     name: 'Email',
            //     icon: <Icons.Inbox />,
            //     path: '/app/email',
            //     grp_name: "app",
            // },
            // {
            //     id: "dash_scrumboard",
            //     name: 'Scrumboard',
            //     icon: <Icons.LayoutKanban />,
            //     path: '/app/taskboard',
            //     iconBadge: <HkBadge bg="primary" size="sm" pill className="position-top-end-overflow">3</HkBadge>,
            //     childrens: [
            //         {
            //             name: 'All Boards',
            //             path: '/app/taskboard/projects-board',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Project Kanban',
            //             path: '/app/taskboard/kanban-board',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Pipeline Kanban',
            //             path: '/app/taskboard/pipeline',
            //             grp_name: "app",
            //         },
            //     ]
            // },

            // {
            //     id: "dash_contact",
            //     name: 'Contact',
            //     icon: <Icons.Notebook />,
            //     path: '/app/contacts',
            //     childrens: [
            //         {
            //             name: 'Contact List',
            //             path: '/app/contacts/contact-list',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Contact Cards',
            //             path: '/app/contacts/contact-cards',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Edit Contact',
            //             path: '/app/contacts/edit-contact',
            //             grp_name: "app",
            //         },
            //     ]
            // },
            // {
            //     id: "dash_file",
            //     name: 'File Manager',
            //     icon: <Icons.FileCheck />,
            //     path: '/app/file-manager',
            //     childrens: [
            //         {
            //             name: 'List View',
            //             path: '/app/file-manager/list-view',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Grid View',
            //             path: '/app/file-manager/grid-view',
            //             grp_name: "app",
            //         },
            //     ]
            // },
            // {
            //     name: 'Gallery',
            //     icon: <Icons.Photo />,
            //     path: '/app/gallery',
            //     grp_name: "app",
            // },
            // {
            //     id: "dash_task",
            //     name: 'Todo',
            //     icon: <Icons.ListDetails />,
            //     path: '/app/todo',
            //     badge: <HkBadge bg="success" soft className="ms-2">2</HkBadge>,
            //     childrens: [
            //         {
            //             name: 'Tasklist',
            //             path: '/app/todo/task-list',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Gantt',
            //             path: '/app/todo/gantt',
            //             grp_name: "app",
            //         },
            //     ]
            // },
            // {
            //     id: "dash_blog",
            //     name: 'Blog',
            //     icon: <Icons.Browser />,
            //     path: '/app/blog',
            //     childrens: [
            //         {
            //             name: 'Posts',
            //             path: '/app/blog/posts',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Add New Post',
            //             path: '/app/blog/add-new-post',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Post Detail',
            //             path: '/app/blog/post-detail',
            //             grp_name: "app",
            //         },
            //     ]
            // },
            // {
            //     id: "dash_invoice",
            //     name: 'Invoices',
            //     icon: <Icons.FileDigit />,
            //     path: '/app/invoices',
            //     childrens: [
            //         {
            //             name: 'Invoice List',
            //             path: '/app/invoices/invoice-list',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Invoice Templates',
            //             path: '/app/invoices/invoice-templates',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Create Invoice',
            //             path: '/app/invoices/create-invoice',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Invoice Preview',
            //             path: '/app/invoices/invoice-preview',
            //             grp_name: "app",
            //         },
            //     ]
            // },
            // {
            //     id: "dash_integ",
            //     name: 'Integrations',
            //     icon: <Icons.Code />,
            //     path: '/app/integrations',
            //     childrens: [
            //         {
            //             name: 'All app',
            //             path: '/app/integrations/all-app',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'App Detail',
            //             path: '/app/integrations/integrations-detail',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Integrations',
            //             path: '/app/integrations/integration',
            //             grp_name: "app",
            //         },
            //     ]
            // },
        ]
    },

    {
        group: 'Pages',
        contents: [
            // {
            //     id: "dash_pages",
            //     name: 'Authentication',
            //     icon: <Icons.UserPlus />,
            //     path: '/auth',
            //     childrens: [
            //         {
            //             id: "dash_log",
            //             name: 'Log In',
            //             path: '/auth',
            //             childrens: [
            //                 // {
            //                 //     name: 'Login',
            //                 //     path: '/auth/login',
            //                 // },
            //                 {
            //                     name: 'Login Simple',
            //                     path: '/auth/login',
            //                 }
            //                 ,
            //                 // {
            //                 //     name: 'Login Classic',
            //                 //     path: '/auth/login-classic',
            //                 // },
            //             ]
            //         },
            //         {
            //             id: "dash_sign",
            //             name: 'Sign Up',
            //             path: '/auth',
            //             childrens: [
            //                 // {
            //                 //     name: 'Signup',
            //                 //     path: '/auth/signup',
            //                 // },
            //                 {
            //                     name: 'Signup Simple',
            //                     path: '/auth/signup',
            //                 },
            //                 // {
            //                 //     name: 'Signup Classic',
            //                 //     path: '/auth/signup-classic',
            //                 // },
            //             ]
            //         },
            //         {
            //             name: 'Lock Screen',
            //             path: '/auth/lock-screen',
            //         },
            //         {
            //             name: 'Reset Password',
            //             path: '/auth/reset-password',
            //         },
            //         {
            //             name: 'Error 404',
            //             path: '/error-404',
            //         },
            //         {
            //             name: 'Error 503',
            //             path: '/auth/error-503',
            //         },
            //     ]
            // },
            // {
            //     id: "dash_profile",
            //     name: 'Profile',
            //     icon: <Icons.UserSearch />,
            //     path: '/app',
            //     badgeIndicator: <HkBadge bg="danger" indicator className="position-absolute top-0 start-100" />,
            //     childrens: [
            //         {
            //             name: 'Profile',
            //             path: '/app/profile',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Edit Profile',
            //             path: '/app/edit-profile',
            //             grp_name: "app",
            //         },
            //         {
            //             name: 'Account',
            //             path: '/app/account',
            //             grp_name: "app",
            //         },
            //     ]
            // },

        ]
    },

    {
        group: 'Documentation',
        contents: [
            // {
            //     name: 'Documentation',
            //     icon: <Icons.FileCode2 />,
            //     path: 'https://nubra-ui-react.netlify.app/introduction',
            // },
            // {
            //     name: 'Components',
            //     icon: <Icons.Layout />,
            //     path: 'https://nubra-ui-react.netlify.app/avatar',
            // },
        ]
    },
]