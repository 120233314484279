import { api } from '../../api'
import { GET_USER_ACCOUNT_FULFILLED, GET_USER_ACCOUNT_PENDING, GET_USER_ACCOUNT_REJECTED } from '../constants/UserDetails'
export const getUserDetails = async (dispatch, getState) => {
    try {
        dispatch({ type: GET_USER_ACCOUNT_PENDING, pending: true })
        const { data } = await api.get('/users/profile')
        console.log(data)
        dispatch({ type: GET_USER_ACCOUNT_FULFILLED, payload: data.data })
    } catch (error) {
        console.log(error)
        dispatch({ type: GET_USER_ACCOUNT_REJECTED, error: error.message })
    }
}