import React, { useState } from 'react';
import LeadListHeader from '../LeadListHeader';
import LeadTableBody from './LeadTableBody';
import classNames from 'classnames';
import { useEffect } from 'react';
import axios from 'axios'
import AlertMsg from '../../../utils/Alert';
import { useSelector } from 'react-redux';
import { api } from '../../../api';
const LeadList = () => {
    const [reload, setReload] = useState(false)
   
    return (

        <div className="contactapp-detail-wrap">
            <LeadListHeader  reload={reload} setReload={setReload} />
            <LeadTableBody  reload={reload} setReload={setReload}/>
        </div>


    )
}

export default LeadList
