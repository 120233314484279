import React from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import RegexUtils from '../../../../utils/RegexUtils';
import { useEffect } from 'react';
import { api } from '../../../../api';
import { useState } from 'react';
import AlertMsg from '../../../../utils/Alert';

const EditInfo = ({ reload, setReload, info, show, hide }) => {
    const {
        register,
        formState: { errors },
        getValues,
        setValue,
        handleSubmit,
        reset
    } = useForm({ criteriaMode: 'all', mode: 'all' })
    const [loading, setLoading] = useState(false)
    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
    useEffect(() => {
        reset()
        setValue('name', info.fullName)
        setValue('email', info.email)
        setValue('phone', info.phone)
        setValue('companyname', info.companyName)
        setValue('address', info.address)


    }, [show])
    const updateLeadInfo = async () => {
        try {
            setLoading(true)
            const { data } = await api.patch('/leads/' + info.id, {
                fullName: getValues('name'),
                companyName: getValues('companyname'),
                email: getValues('email'),
                phone: getValues('phone'),
                address: getValues('address'),
            }
            )
            let details = {
                variant: 'success',
                title: "Info Updated",
                msg: "Lead Information updated"
            }
            setAlertData(details)
            setAlertShow(true)
            setReload(!reload)
        } catch (error) {
            console.log(error)
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)
        }
        setLoading(false)
    }
    return (
        <Modal show={show} onHide={hide} size="lg" centered >
            <Modal.Body>
                <Button bsPrefix="btn-close" type='button' onClick={hide}>
                    <span aria-hidden="true">×</span>
                </Button>
                <h5 className="mb-5">Edit Lead Information</h5>
                <AlertMsg className='w-8' data={alertData} show={showAlert} setShow={setAlertShow} />

                <Form onSubmit={handleSubmit(updateLeadInfo)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSubmit(updateLeadInfo);
                        }
                    }}>
                    <h6 className='text-primary mb-3'>Profile Information</h6>
                    <Row className="gx-2">
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                    type="text" placeholder="Full Name" name="name"
                                    {...register("name", {
                                        required: "Name is required",
                                        pattern: {
                                            value: RegexUtils.NAME,
                                            message: "Valid Full name is required",
                                        },
                                    })} />
                                {errors.name && <p className='text-danger mt-1'>Valid Full Name is required</p>}

                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Email ID</Form.Label>
                                <Form.Control type="email" placeholder="Eamil ID" name="email"
                                    {...register("email", {
                                        required: "email is required",
                                        pattern: {
                                            value: RegexUtils.EMAIL,
                                            message: "Invalid Email ID",
                                        },
                                    })} />
                                {errors.email && <p className='text-danger mt-1'>Email is invalid</p>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="gx-2">
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" placeholder="Phone No" name="phone"
                                    {...register("phone", {
                                        required: "phone is required",
                                        pattern: {
                                            value: RegexUtils.PHONE,
                                            message: "Invalid Phone Number",
                                        },
                                    })} />
                                {errors.phone && <p className='text-danger mt-1'>Phone number is invalid</p>}

                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="separator" />
                    <h6 className='text-primary mb-3'>Company Information</h6>
                    <Row className="gx-2">
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control type="text" placeholder="Company Name" name="cname"
                                    {...register("companyname", {
                                        required: "Company Name is required"
                                    })} />
                                {errors.companyname && <p className='text-danger mt-1'>Company Name is required</p>}

                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Address</Form.Label>
                                <Form.Control type="text" placeholder="Address" name="address"
                                    {...register("address", {
                                        required: "Address is required"
                                    })} />
                                {errors.address && <p className='text-danger mt-1'>Address is required</p>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Modal.Footer className="align-items-center">
                        {loading &&
                            <div className='d-flex justify-content-center mb-2'>
                                <Spinner animation="border" variant="primary" />
                            </div>
                        }
                        <Button variant="secondary" onClick={hide}>Close</Button>
                        <Button type='submit' variant="primary"  >Update</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>

        </Modal >
    )
}

export default EditInfo;
