import React from 'react'
import Note from './Note'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { api } from '../../../../api';
import dateFormatter from '../../../../utils/DateFormatter';

const Body = ({ setAlertData, setAlertShow, setReload, leadId, notes }) => {
    const {
        register: registerNote,
        formState: { errors: noteErrors },
        getValues: getNoteValues,
        handleSubmit: handleNoteSubmit,
    } = useForm({ criteriaMode: "all", mode: "all" });
    const createNote = async () => {
        try {
            const newNote = {
                content: getNoteValues('note'),
                leadId
            }
            const { data: response } = await api.post('/leads/notes/add', newNote)
            console.log(response)
            let details = {
                variant: 'success',
                title: 'Success',
                msg: "Note added"
            }
            setAlertData(details)
            setAlertShow(true)
            setReload(r => !r)
        } catch (error) {
            console.log(error)
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)

        }
    }
    return (
        <>
            <Form onSubmit={
                handleNoteSubmit(createNote)
            }
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        // e.preventDefault();
                        handleNoteSubmit(createNote);
                    }
                }}>
                <Row>
                    <Col md={12} as={Form.Group} className="mb-3" >
                        <div className="form-label-group">
                            <Form.Label>Write a Note</Form.Label>
                            <small className="text-muted">1200</small>
                        </div>
                        <Form.Control
                            {...registerNote("note", {
                                required: "note is required"
                            })}
                            // value={note} onChange={e => setNote(e.target.value)}

                            as="textarea" rows={8} placeholder="Write an internal note" />
                        {noteErrors.note && <p className='text-danger mt-1'>Note is required</p>}

                    </Col>
                </Row>
                <Button type='submit' variant="outline-primary" className="mnw-125p">
                Add Note
                </Button>
            </Form>

            {notes.map((note, index) => {
                const date1 = new Date(note.createdAt).toLocaleDateString();
                const date2 = notes?.[index - 1] ? new Date(notes[index - 1].createdAt).toLocaleDateString() : null;

                return <div>
                    {date1 != date2 &&
                        <div className="title-sm  text-primary mt-5 mb-3">{dateFormatter(note.createdAt,'dd-MMM-yy','-')}</div>}
                    <Note note={note} />
                </div>
            })}
        </>
    )
}

export default Body
