import axios from 'axios'
export const api = axios.create({
    baseURL: '/api'
})
api.interceptors.request.use((config) => {
    const authToken = localStorage.getItem('token');
    if (authToken) {
      config.headers['auth-token'] = authToken;
    }
    return config;
  });
api.interceptors.response.use(
    (response) => {
        // const currentPath = window.location.pathname;
        // let authPath = currentPath.split('/')
        // authPath = authPath[1]
        // // alert(response.config.url)
        // if ((authPath === 'auth' || !authPath) && response.config.url === '/users/profile')
        //     return window.location.replace('/dashboard')
        return response
    },
    (error) => {
        console.log(error)
        if (error?.response?.status == 401) {
            const currentPath = window.location.pathname;
            let authPath = currentPath.split('/')
            authPath = authPath[1]
            localStorage.removeItem('token')
            if (authPath !== 'auth' && authPath)
                return window.location.replace('/auth/signin')
        }
        return Promise.reject(error)
    }
)