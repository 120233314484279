import React from 'react'

const LandingPage = () => {
  return (
    <div>
      <h1>Landing Page</h1>
      <a href='/dashboard'>Dashboard</a><br/>
      <a href='/auth/signin'>sign in</a><br/>
      <a href='/auth/signup'>sign up</a>

    </div>
  )
}

export default LandingPage
