import { useMemo, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Badge, Button, Dropdown, Form } from "react-bootstrap";
import HkBadge from "../../components/@hk-badge/@hk-badge";
import classNames from 'classnames'
import { MoreVertical, Plus } from "react-feather";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import AlertMsg from "../../utils/Alert";
import { api } from "../../api";
import RenameStage from "./RenameStage";
import { useDispatch } from "react-redux";
import { getStages } from "../../redux/action/Stages";
import AddStage from "./AddStage";







const Body = () => {
    const stages = useSelector(state => state.stagesReducer.stages)
    const dispatch = useDispatch()
    const [items, setItems] = useState([]);
    const [showUpdateBtn, setShowUpdateBtn] = useState('none')
    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
    const [showRename, setShowRename] = useState(false)
    const [showAddStage, setShowAddStage] = useState(false)
    const [renameStageIndex, setRenameStageIndex] = useState(0)
    console.log(stages)
    useEffect(() => {
        console.log(stages)
        setItems(() => stages.slice(0, stages.length - 2))
    }, [stages])
    const reorder = (startIndex, endIndex) => {
        let result = Array.from(items);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result = result.map((ele, index) => {
            return { ...ele, order: index }
        })
        return result;
    }
    const onDragEnd = result => {
        if (!result.destination || result.source.index === result.destination.index) return;
        const reorderedItems = reorder(
            result.source.index,
            result.destination.index
        );
        setShowUpdateBtn('flex')
        setItems(reorderedItems);
    };
    const updateStages = async () => {
        try {
            const { data } = await api.patch(`/leads/stages/edit`, { data: items })
            console.log(data)
            let details = {
                variant: 'success',
                title: 'Success',
                msg: "Stage Updated"
            }
            dispatch(getStages)
            setAlertData(details)
            setAlertShow(true)
        } catch (error) {
            console.log(error)
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)
        }
        setShowUpdateBtn('none')
    }
    const renameStage = (index) => {
        setRenameStageIndex(index)
        setShowRename(true)

    }
    return (
        <div className="w-lg-50 ms-lg-5">
            <AlertMsg className='w-100' data={alertData} show={showAlert} setShow={setAlertShow} />
            <RenameStage show={showRename} renameStageIndex={renameStageIndex} hide={() => setShowRename(false)} items={items} setItems={setItems} callBack={updateStages} />
            <AddStage show={showAddStage} hide={() => setShowAddStage(false)} />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            className=""
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            className={classNames(" align-items-center  advance-list-item single-task-list active-todo my-3", { "selected": true })}
                                            key={item.id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <span className="todo-text text-dark text-truncate"  >
                                                            {item.stageType}
                                                        </span>
                                                    </div>
                                                </div>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant='flush-light' className="btn-icon btn-rounded flush-soft-hover no-caret">
                                                        <span className="icon">
                                                            <span className="feather-icon">
                                                                <MoreVertical />
                                                            </span>
                                                        </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu align="end" >
                                                        <Dropdown.Item className="edit-task" onClick={() => renameStage(index)}>Rename Stage</Dropdown.Item>
                                                        <Dropdown.Item className="view-task">Delete Stage</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            {provided.placeholder}
                                        </div>


                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <Button variant="primary" className="ms-5 mt-2 btn-icon btn-rounded flush-soft-hover"
                onClick={() => setShowAddStage(true)}>
                <span className="icon">
                    <span className="feather-icon">
                        <Plus />
                    </span>
                </span>
            </Button>

            <div className={`d-${showUpdateBtn} mx-5  justify-content-end`}>
                <Button variant="light" className="mx-5" onClick={() => {
                    setItems(() => stages.slice(0, stages.length - 2))
                    setShowUpdateBtn('none')
                }}>Cancel</Button>
                <Button variant="primary" onClick={updateStages}>Update</Button>
            </div>
        </div>
    );
};

export default Body;
