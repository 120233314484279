import React from 'react'
import { Container } from 'react-bootstrap'
import Body from './Body'

const Setting = () => {
  return (
    <div className="hk-pg-body">
        <Body />
    </div>
  )
}

export default Setting
