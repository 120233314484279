import React from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import AlertMsg from '../../../utils/Alert';
import RegexUtils from '../../../utils/RegexUtils';
import { api } from '../../../api';
const CreateNewEmployee = ({ show, close, reload, setReload }) => {


    const [loading, setLoading] = useState(false)

    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })
    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit,
    } = useForm({ criteriaMode: "all", mode: "all" });

    const createEmployee = async () => {
        try {
            setLoading(true)
            const { data: response } = await api.post('/admin/user', {
                fullName: getValues('name'),
                email: getValues('email'),
                phone: getValues('phone'),
                role: getValues('role'),
            }
            )
            console.log(response)
            setReload(!reload)
            close()
        } catch (error) {
            console.log(error)
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)
        }
        setLoading(false)

    }
    return (
        <Modal show={show} onHide={close} centered size="lg" className="add-new-contact" >
            <Modal.Body>
                <Button bsPrefix="btn-close" type='button' onClick={close}>
                    <span aria-hidden="true">×</span>
                </Button>
                <h5 className="mb-5">Create New Employee</h5>
                <AlertMsg className='w-8' data={alertData} show={showAlert} setShow={setAlertShow} />

                <Form onSubmit={handleSubmit(createEmployee)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSubmit(createEmployee);
                        }
                    }}>


                    <Row className="gx-2">
                        <div className="col-sm-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                    {...register("name", {
                                        required: "Name is required",
                                        pattern: {
                                            value: RegexUtils.NAME,
                                            message: "Valid Full name is required",
                                        },
                                    })}
                                    type="text" />
                                {errors.name && <p className='text-danger mt-1'>Valid Full Name is required</p>}

                            </Form.Group>
                        </div>
                        <div className="col-sm-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Email ID</Form.Label>
                                <Form.Control
                                    {...register("email", {
                                        required: "Email is invalid",
                                        pattern: {
                                            value: RegexUtils.EMAIL,
                                            message: "Email is invalid",
                                        },
                                    })}
                                    // value={email} onChange={e => setEmail(e.target.value)} 
                                    type="email" />
                                {errors.email && <p className='text-danger mt-1'>Email is invalid</p>}

                            </Form.Group>
                        </div>

                    </Row>
                    <Row className="gx-3">

                        <div className="col-sm-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    {...register("phone", {
                                        required: "Phone ID is required",
                                        pattern: {
                                            value: RegexUtils.PHONE,
                                            message: "Phone number is invalid",
                                        },
                                    })}
                                    // value={phone} onChange={e => setPhone(e.target.value)} 
                                    type="text" />
                                {errors.phone && <p className='text-danger mt-1'>Phone number is invalid</p>}

                            </Form.Group>
                        </div>
                        <div className="col-sm-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Role</Form.Label>

                                <Form.Select
                                    {...register("role")}
                                >
                                    <option value={'OPERATOR'}>Operator</option>
                                    <option value={'ADMIN'}>Admin</option>
                                </Form.Select>
                            </Form.Group>

                        </div>
                    </Row>



                    <Modal.Footer className="align-items-center">
                        {loading &&
                            <div className='d-flex justify-content-center mb-2'>
                                <Spinner animation="border" variant="primary" />
                            </div>
                        }
                        <Button variant="secondary" type='button' onClick={close}>Close</Button>
                        <Button type='submit' variant="primary">Create Employee</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>

        </Modal>
    )
}

export default CreateNewEmployee;
