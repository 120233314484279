import React from 'react'
import { Button } from 'react-bootstrap'
import ReactShowMoreText from 'react-show-more-text'
import HkTooltip from '../../../../components/@hk-tooltip/HkTooltip'
import { Edit2, Trash2 } from 'react-feather'

const Note = ({note}) => {
    return (
        <div>
           <ul className="activity-thread d-flex border shadow-lg p-3 mb-2 bg-white rounded">
                <li className=' w-90 px-2'>
                    <div className="media">
                        <div className="media-body">
                            <div>
                                <ReactShowMoreText
                                    lines={3}
                                    more="more"
                                    less="less"
                                    className="content-css"
                                    anchorClass="show-more-less-clickable"
                                    // onClick={executeOnClick}
                                    expanded={false}
                                    truncatedEndingComponent={"... "}
                                >
                                    {note.content}
                                </ReactShowMoreText>
                                {/* <div className="activity-time">{note.time}</div> */}
                            </div>
                        </div>
                    </div>
                </li>
                <li className='w-10'>
                    <div className='d-flex flex-column justify-content-between h-100'>
                        {/* <div className='d-flex flex-row justify-content-between w-100'>
                            <Button variant="light" size="xs" className="btn-icon btn-rounded" >
                                <HkTooltip placement="top" title="Edit" >
                                    <span className="icon">
                                        <span className="feather-icon">
                                            <Edit2 />
                                        </span>
                                    </span>
                                </HkTooltip>
                            </Button>
                            <Button variant="light" size="xs" className="btn-icon btn-rounded"  >
                                <HkTooltip placement="top" title="Edit" >
                                    <span className="icon">
                                        <span className="feather-icon">
                                            <Trash2 />
                                        </span>
                                    </span>
                                </HkTooltip>
                            </Button>
                        </div> */}
                        <div style={{ textAlign: 'right', fontSize: '12px' }} className="">{note.lastupdate}</div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default Note
